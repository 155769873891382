import { Component, OnInit } from '@angular/core';
import { Survey, SurveyQuery, SurveyType } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-featured-surveys',
  templateUrl: './featured-surveys.component.html',
  styleUrls: ['./featured-surveys.component.scss'],
})
export class FeaturedSurveysComponent implements OnInit {
  screenType = ScreenType;
  mixedSurveys$: Observable<Survey[]>;
  customOptions: OwlOptions = {
    autoHeight: true,
    margin: OwlCarouselSettings.Margin,
    autoWidth: true,
    pullDrag: false,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      540: { items: 1 },
      740: { items: 1 },
    },
    dots: true
  };

  customOptions2: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { autoWidth: false },
      740: { autoWidth: true, autoHeight: false },
    },
    dots: true
  };

  constructor(private surveyQuery: SurveyQuery) {}

  ngOnInit(): void {
    let predictors$ = this.surveyQuery
      .selectFavoriteSurvey(SurveyType.Predictor)
      .pipe(map((survey) => survey.filter((s) => !s.ComingSoon)));
    let polls$ = this.surveyQuery
      .selectFavoriteSurvey(SurveyType.Poll)
      .pipe(map((survey) => survey.filter((s) => !s.ComingSoon)));
    let quizzes$ = this.surveyQuery
      .selectFavoriteSurvey(SurveyType.Trivia)
      .pipe(map((survey) => survey.filter((s) => !s.ComingSoon)));

    this.mixedSurveys$ = combineLatest([ predictors$, polls$, quizzes$ ]).pipe(
      map(([ predictor, polls, quizzes ]) => ([ ...polls, ...quizzes, ...predictor ]))
    );
  }

  trackByFn(index: number, item: Survey){
    return index;
  }
}
