<ng-container *ngIf="mixedSurveys$ | async as mixedSurveyItems">
  <div *ngIf="mixedSurveyItems.length > 0">
    <h4 class="pt-4 pb-2 text-start">{{ 'home.page.featured.games.title' | translate }}</h4>
    <owl-carousel-o *ifScreenSize="screenType.Desktop" [options]="customOptions" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let item of mixedSurveyItems; trackBy: trackByFn">
        <ng-template carouselSlide [width]="930">
          <fgb-survey-item [survey]="item"></fgb-survey-item>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <owl-carousel-o *ifScreenSize="screenType.Mobile" [options]="customOptions2" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let item of mixedSurveyItems; trackBy: trackByFn">
        <ng-template carouselSlide [width]="395">
          <fgb-survey-item [survey]="item"></fgb-survey-item>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</ng-container>
