<div class="d-none d-lg-flex banner">
  <h1>{{ 'payment.page.title' | translate }}</h1>
</div>

<div class="container d-lg-none d-block my-4">
  <fgb-barcode></fgb-barcode>
</div>

<div class="d-block d-lg-none">
  <ul class="nav nav-secondary payment-nav">
    <a
      class="nav-item primary-theme py-2"
      [routerLink]="['/payment']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="icon material-icons material-mobile-nav" aria-hidden="true">credit_card</span>
      <label>{{ 'navbar.payment' | translate }}</label>
    </a>
    <a
      class="nav-item primary-theme py-2"
      [routerLink]="['/ecashhistory']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="icon material-icons material-mobile-nav" aria-hidden="true">history</span>
      <label>{{ 'navbar.ecash.history' | translate }}</label>
    </a>
  </ul>
</div>

<div class="container d-none d-lg-block">
  <fgbcl-ecash-description></fgbcl-ecash-description>
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
  ></fgbcl-card-token-list>
</div>

<div class="container d-block d-lg-none">
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
  ></fgbcl-card-token-list>
  <hr class="horizontal-line w-100" />
  <fgbcl-ecash-description></fgbcl-ecash-description>
</div>

<div class="container d-none d-lg-block mt-4">
  <hr class="horizontal-line" />
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
