<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="w-100 bg-white p-3 position-relative popup-add-to-home-screen-div">
    <div class="text-primary cursor-pointer text-end position-absolute popup-close-button">
      <span class="material-icons text-end" (click)="closeModal()">cancel</span>
    </div>
    <!-- Android -->
    <div class="m-2 text-center font-size-14 text-grey" *ngIf="detectedDevice === 'android'">
      <div class="mb-2 me-3">
        <img src="favicon.svg" draggable="false" class="popup-image" />
      </div>
      <div class="my-3 mx-2 fw-bold">{{ 'popup.add.to.home.screen.text' | translate }}</div>
      <div>{{ 'android.step.1.home.screen' | translate }}</div>
      <div>{{ 'android.step.2.home.screen' | translate }}</div>
      <div>{{ 'android.step.3.home.screen' | translate }}</div>
    </div>
    <!-- iOS with Safari -->
    <div class="m-2 font-size-14 text-grey" *ngIf="detectedDevice === 'ios'">
      <div class="d-flex align-items-center mb-3">
        <img src="favicon.svg" draggable="false" class="popup-image me-3" />
        <div class="fw-bold">{{ 'popup.add.to.home.screen.text' | translate }}</div>
      </div>
      <div>1. Click <i class="icon material-icons">ios_share</i> then</div>
      <div class="d-flex align-items-center">
        {{ 'ios.step.2.home.screen' | translate }} <i class="ms-1 material-icons-outlined">add_box</i>"
      </div>
    </div>
  </div>
  <div class="popup-add-to-home-screen-pointer-div" *ngIf="detectedDevice === 'ios'"></div>
</ng-container>
