<div class="my-4">
  <div class="survey-answer">
    <div class="survey-answer-container justify-content-center">
      <div
        class="survey-answer-item w-100"
        *ngFor="let answer of selectedAnswers"
        [ngClass]="{ 'predictor-image-and-text': displayType == answerDisplayType.ImageAndText }"
      >
        <div>
          <ng-container [ngSwitch]="displayType">
            <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
              <img
                class="image-container border-0"
                src="{{ answer.ImageURL | contentImage : '7' }}"
                alt="{{ answer.ImageURL }}"
              />
              <div class="answer-text text-center text-white bg-primary">
                <span>{{ answer.Title }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="answerDisplayType.Text">
              <div class="answer-text-landscape text-center text-white bg-primary">
                <span>{{ answer.Title }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="answerDisplayType.Image">
              <img
                class="image-container rounded-4"
                src="{{ answer.ImageURL | contentImage : '7' }}"
                alt="{{ answer.ImageURL }}"
              />
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center fw-bold font-size-14 text-primary mt-4 pb-2">{{ 'predictor.pending.title' | translate }}</div>
  <div class="text-center font-size-14 mb-lg-5 mb-3">
    {{ 'predictor.pending.description' | translate }}
  </div>
</div>
