<div *ngIf="portalProduct">
  <div class="rounded sub-card d-flex border">
    <div class="img-container me-3">
      <img alt="" class="product-image rounded" src="{{ portalProduct.PrimaryImage | contentImage : '6' }}" />
    </div>
    <div class="d-flex product-description-container flex-column">
      <div class="product-header d-flex">
        <div
          class="font-size-14"
          [ngClass]="{ 'text-primary': portalProduct.ProductType == 1, 'text-secondary': portalProduct.ProductType == 3 }"
        >
          {{ portalProduct.DisplayName }}
        </div>
        <span class="price-value ms-auto text-primary font-size-16 font-heading">
          {{ portalProduct.MerchantPrice / 100 | FortressCurrencyPipe : '£' }}
        </span>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="font-size-14 subs-description mb-1 pe-3" [innerHTML]="portalProduct.Description"></p>
        </div>
        <a class="font-size-14 fw-bold text-black learn-more mb-3" (click)="openProductDescription(productInfo)">{{
          'subscription.list.item.learn.more' | translate
        }}</a>
      </div>
      <div class="action-buttons mt-auto">
        <button
          class="btn font-size-14 w-100"
          [ngClass]="{
            'btn-tertiary text-primary fw-bold': portalProduct.ProductType == 1,
            'btn-secondary': portalProduct.ProductType == 3
          }"
          (click)="addSubscriptionProductToCart()"
        >
          <span>{{ 'subscription.upgrade.button' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!--Product info modal-->
<ng-template #productInfo let-modal>
  <div class="text-black bg-white">
    <div class="modal-body d-flex flex-column align-items-center">
      <button class="material-icons modal-close-btn text-info" (click)="modal.dismiss('close')">cancel</button>
      <div class="bg-primary w-60 rounded text-center mb-3">
        <img alt="" class="info-product-image rounded my-2" src="{{ portalProduct.PrimaryImage | contentImage : '6' }}" />
      </div>

      <div class="font-heading text-primary font-size-16 mb-2">{{ portalProduct.DisplayName }}</div>
      <h5 class="text-primary">{{ portalProduct.MerchantPrice / 100 | FortressCurrencyPipe : '£' }}</h5>
      <p class="mb-4 mt-3 font-size-12 portal-product-modal-description" [innerHTML]="portalProduct.Description"></p>

      <label class="font-size-14 mb-2">{{ 'subscription.modal.upgrade.label' | translate }}</label>
      <p class="font-size-12">{{ 'subscription.modal.upgrade.message' | translate }}</p>
      <div class="text-center mt-3">
        <button
          class="btn btn-tertiary text-primary upgrade-btn fw-bold"
          (click)="addSubscriptionProductToCart(); modal.dismiss('close')"
        >
          {{ 'subscription.modal.upgrade.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
