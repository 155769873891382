import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EcashService, config } from '@fgb/core';
import { ProviderUiActionResponse } from '@fgb/core';
import { CallBackModel } from '@fgb/core';

@Component({
  selector: 'fgb-portal-product-results-page',
  templateUrl: './portal-product-results-page.component.html',
  styleUrls: ['./portal-product-results-page.component.scss'],
})
export class PortalProductResultsPageComponent implements OnInit {
  response = new ProviderUiActionResponse();

  constructor(private ecashService: EcashService, private router: Router) {}

  ngOnInit() {
    this.processCallBack();
  }

  processCallBack() {
    this.ecashService.callBack(new CallBackModel('', config.baseApiUrl + this.router.url)).subscribe((response) => {
      if (response.Success) {
        this.router.navigateByUrl('portalproducts/success');
      } else {
        this.router.navigateByUrl('portalproducts/failure');
      }
    });
  }
}
