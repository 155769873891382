import { Component, OnInit, Renderer2 } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-popup-add-to-home-screen',
  templateUrl: './popup-add-to-home-screen.component.html',
  styleUrls: ['./popup-add-to-home-screen.component.scss'],
})
export class PopupAddToHomeScreenComponent implements OnInit {
  detectedDevice: string;
  screenSize = ScreenType;

  constructor(private activeModal: NgbActiveModal, private renderer: Renderer2) {
    setTimeout(() => {
      this.renderer.removeClass(document.body, 'modal-open');
    }, 3000);
  }

  ngOnInit() {}

  /** Closes dialog box */
  closeModal() {
    this.activeModal.dismiss();
  }
}
