import { Component, OnInit, Input } from '@angular/core';
import {
  MemberCard,
  MemberCardQuery,
  MemberDetails,
  Balance,
  MemberQuery,
  BalanceQuery,
  config,
  ProductCodeQuery,
} from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
  @Input() showSeating: boolean;
  memberCard$: Observable<MemberCard | undefined>;
  ecash$: Observable<number | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  balance$: Observable<Balance>;
  member$: Observable<MemberDetails | undefined>;
  pointsBalance$: Observable<number | undefined>;
  combinedCard$: Observable<number | undefined>;
  bgtheme: string;
  lowerTier: boolean;
  upperTier: boolean;
  maxTier: boolean;

  constructor(
    private memberCardQuery: MemberCardQuery,
    private memberQuery: MemberQuery,
    private balanceQuery: BalanceQuery,
    private productCodeQuery: ProductCodeQuery
  ) {}

  ngOnInit() {
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.lowerTier = this.productCodeQuery.currentUserIsProductCode(['FREEMEMBER', 'Tier1']);
    this.upperTier = this.productCodeQuery.currentUserIsProductCode(['Tier2', 'Tier3']);
    this.maxTier = this.productCodeQuery.currentUserIsProductCode(['Tier4']);
    this.updateBackgroundColour();
  }

  updateBackgroundColour() {
    if (this.lowerTier) {
      this.bgtheme = 'bg-primary';
    } else if (this.upperTier) {
      this.bgtheme = 'bg-quaternary';
    } else if (this.maxTier) {
      this.bgtheme = 'bg-gold';
    } else {
      this.bgtheme = 'bg-primary';
    }
  }
}
