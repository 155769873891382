<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/login-registration/login-banner.png" />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="bg-primary d-flex d-lg-none justify-content-center align-items-center logo-container">
    <img src="/assets/images/logo.svg" class="logo" />
  </div>
  <div class="user-login-panel">
    <router-outlet></router-outlet>
  </div>
</div>
