<!--membership options-->
<ng-container *inProductCode="productCode.Free">
  <div *ngIf="membershipPortalProducts?.length && !foundMembership">
    <div class="mt-3">
      <h5 class="mt-lg-0">{{ 'subscriptions.list.membership.title' | translate }}</h5>
    </div>
    <div class="row">
      <ng-container *ngFor="let membProduct of membershipPortalProducts">
        <div class="col-12 subscription-item">
          <fgb-subscriptions-list-item [portalProduct]="membProduct"></fgb-subscriptions-list-item>
        </div>
      </ng-container>
    </div>
  </div>

  <!--No new products or active products-->
  <ng-container *ngIf="allProducts == 0">
    <div>
      <p class="text-info text-center font-size-14">Currently Unavailable</p>
    </div>
  </ng-container>
</ng-container>
