<div class="card p-2 mt-4" [class.disabled]="disabled || isClaimed || inactive || outOfStock">
  <div class="d-flex">
    <img class="benefitImage" src="{{ benefit.ImageFileName | contentImage : '1' }}" />
    <div class="w-100 px-2">
      <h6 class="text-secondary font-size-14 fw-bold">{{ benefit.Name }}</h6>
      <h6 class="font-size-12">Exp: {{ benefit.EndDate | UtcToLocaltimePipe : 'MMMM D, YYYY' }}</h6>
      <p class="cursor-pointer font-size-12 fw-bold" (click)="openReadMoreModal(benefit)">Read More...</p>
      <div class="w-100">
        <button
          *ngIf="!disabled && !isClaimed && !inactive && !outOfStock; else notActive"
          class="btn bg-secondary text-white w-100"
          (click)="openClaimModal(benefit)"
        >
          Add benefit
        </button>
        <ng-template #notActive>
          <button *ngIf="statusText" class="btn bg-secondary text-white w-100 disabled" (click)="openClaimModal(benefit)">
            {{ statusText }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
