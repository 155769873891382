<div class="banner" role="banner">
  <h1>{{ 'benefits.page.title' | translate }}</h1>
</div>

<div class="container" *ngIf="claimLimit != undefined">
  <div class="row" *ngIf="claimedBenefits$ | async as claimedBenefits">
    <div class="col-md-12 mt-4">
      <fgb-benefit-header [numberClaimed]="numberOfBenefitsClaimed" [claimLimit]="claimLimit"></fgb-benefit-header>
    </div>
    <div class="col-md-12 mt-4">
      <h5>{{ 'current.benefits.item.list.title' | translate | uppercase }}</h5>
      <fgb-benefit-claimed-list [benefitsClaimed]="claimedBenefits" [claimLimit]="claimLimit"> </fgb-benefit-claimed-list>
    </div>
    <div class="col-md-12 mt-4" *ngIf="benefits$ | async as benefits">
      <ng-container *ngIf="benefits.length">
        <h5>{{ 'benefits.item.list.title' | translate | uppercase }}</h5>
        <p class="font-size-14">{{ 'benefits.item.list.description' | translate }}</p>
        <fgb-benefit-item-list [benefits]="benefits" [disabled]="isClaimLimitReached()"></fgb-benefit-item-list>
      </ng-container>
    </div>
  </div>
</div>
