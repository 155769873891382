<h5 class="text-start mt-5 mb-2">{{ 'score.predictor.rules.title' | translate }}</h5>
<div class="col-12 games-rules-container">
  <div class="row">
    <div ngbAccordion #a="ngbAccordion" [destroyOnHide]="false" class="w-100 mt-lg-0">
      <div ngbAccordionItem id="ngb-panel-0">
        <div ngbPanelHeader>
          <h2 ngbAccordionHeader class="mt-0">
            <button ngbAccordionButton>{{ 'score.predictor.rules.select.player.title' | translate }}</button>
          </h2>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="row py-0">
              <div class="content col-12">
                <div class="fantasy text-info">
                  <div class="font-size-12">
                    {{ 'score.predictor.rules.select.player.text' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordionItem id="ngb-panel-1">
        <div ngbPanelHeader>
          <h2 ngbAccordionHeader class="mt-0">
            <button ngbAccordionButton>{{ 'score.predictor.rules.deadlines.title' | translate }}</button>
          </h2>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="row">
              <div class="content col-12">
                <div class="fantasy text-info">
                  <div class="font-size-12">
                    {{ 'score.predictor.rules.deadlines.text' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordionItem id="ngb-panel-2">
        <div ngbPanelHeader>
          <h2 ngbAccordionHeader class="mt-0">
            <button ngbAccordionButton>{{ 'score.predictor.rules.scoring.title' | translate }}</button>
          </h2>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="row">
              <div class="content col-12">
                <div class="fantasy text-info">
                  {{ 'score.predictor.rules.scoring.text' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordionItem id="ngb-panel-3">
        <div ngbPanelHeader>
          <h2 ngbAccordionHeader class="mt-0">
            <button ngbAccordionButton>{{ 'score.predictor.rules.changing.player.title' | translate }}</button>
          </h2>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div class="row">
              <div class="content col-12">
                <div class="fantasy text-info">
                  <div class="font-size-12">
                    {{ 'score.predictor.rules.changing.player.text' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
