<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/login-registration/login-banner.png" />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->

  <div class="right-side-login">
    <div class="right-side-container text-center">
      <div class="text-primary icon material-icons mb-4">engineering</div>
      <h5 class="text-primary mb-4">{{ 'maintenance.page.title' | translate }}</h5>
      <div
        class="panel-text font-size-14 mb-4 text-center"
        [innerHTML]="'maintenance.page.details' | translate | markdownTranslate"
      ></div>
    </div>
  </div>
</div>
