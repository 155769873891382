import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { config, AnnouncementService, MemberQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PopupAddToHomeScreenComponent } from 'src/app/modules/user/components/popup-add-to-home-screen/popup-add-to-home-screen.component';
import { ProductCode } from 'src/app/shared/utilities/product-code';

const modalCookieName = 'addToHomeScreenModalSeen';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  config = config;
  screenSize = ScreenType;
  productCode = ProductCode;
  name$: Observable<string>;
  private destoryRef = inject(DestroyRef);

  constructor(
    private announcementService: AnnouncementService,
    private memberQuery: MemberQuery,
    private modalService: NgbModal,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName}`;
        }
        return '';
      })
    );

    let notSeenModal = this.cookieService.get(modalCookieName) !== 'true';

    if (window.matchMedia('(max-width: 991px)').matches && notSeenModal) {
      let userAgent = navigator.userAgent;
      setTimeout(() => {
        // android detection
        if (/android/i.test(userAgent)) {
          this.openModal('popup-add-to-home-screen-android-modal-window', 'android');
        }
        // iOS detection
        if (/iPad|iPhone|iPod/g.test(userAgent)) {
          this.openModal('popup-add-to-home-screen-ios-modal-window', 'ios');
        }
      }, 3000);
    }
    firstValueFrom(this.announcementService.fetchAnnouncements());
  }

  private openModal(windowClass: string, deviceType: 'android' | 'ios') {
    const modalRef = this.modalService.open(PopupAddToHomeScreenComponent, {
      windowClass: windowClass,
      modalDialogClass: 'popup-add-to-home-screen-dialog',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: false,
    });
    modalRef.componentInstance.detectedDevice = deviceType;

    modalRef.dismissed
      .pipe(
        tap(() => {
          this.cookieService.set(modalCookieName, 'true', { expires: 365, sameSite: 'Strict' });
        }),
        takeUntilDestroyed(this.destoryRef)
      )
      .subscribe();
  }
}
