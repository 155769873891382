<div class="card">
  <div class="mt-5" *ngIf="loadingPaymentForm && currentStep != 2">
    <fgbcl-loading></fgbcl-loading>
  </div>
  <div class="card-body" *ngIf="portalProducts">
    <div class="row">
      <div class="col-lg-12 position-relative">
        <!--Membership Options-->
        <ng-container *ngIf="currentStep == 0">
          <div class="row h-100">
            <div class="col-lg-7 d-none d-lg-block login-banner"></div>
            <div class="col-lg-5 personal-details-panel position-relative">
              <div class="h-100 terms-panel">
                <div class="w-100 mb-4 d-lg-none">
                  <img alt="" src="assets/images/placeholder.jpg" class="kid-logo w-100" />
                </div>
                <h4 class="text-primary mb-4 px-lg-1 px-4">{{ 'kids.membership.upgrade.title' | translate }}</h4>
                <p
                  class="font-size-14 px-lg-1 px-4"
                  [innerHtml]="'kids.membership.upgrade.description' | translate | markdownTranslate"
                >
                  {{ 'kids.membership.upgrade.description' | translate }}
                </p>

                <div class="child-names-section mb-2 px-lg-1 px-4">
                  <hr class="my-1 bg-muted" />
                  <div *ngFor="let child of childUpgrades; let i = index">
                    <div class="py-2">
                      <h5 class="text-primary font-size-18">Child {{ i + 1 }}</h5>
                      <div class="w-100">
                        <div class="d-flex flex-column flex-lg-row">
                          <label class="mb-0 child-name me-2">{{ child.FirstName + ' ' + child.LastName }}</label>
                          <p class="mb-0 font-size-12">({{ child.DateOfBirth | date }})</p>
                        </div>
                        <div class="flex-column">
                          <div class="form-group me-2">
                            <select
                              class="form-control bg-muted mt-2 p-1 border-0 font-size-12"
                              [value]="child.Product.ProductId"
                              (change)="childProductChange(child, $event.target.value)"
                            >
                              <option value="0" class="font-size-12">Do Not Upgrade</option>
                              <ng-container *ngFor="let product of portalProducts">
                                <option
                                  class="font-size-12"
                                  [value]="product.PortalProduct.ProductId"
                                  [selected]="child.Product.ProductId == product.PortalProduct.ProductId"
                                >
                                  {{ product.PortalProduct.DisplayName }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="my-1 bg-muted" />
                  </div>
                </div>
              </div>
              <div class="button-container d-flex justify-content-between px-lg-1 px-4">
                <!-- Previous Button -->
                <button (click)="CloseModal()" class="btn btn-primary nav-button fw-bold mb-0">
                  Back
                  <ng-template #noPreviousStep>Back</ng-template>
                </button>

                <!-- Next Button -->
                <button
                  (click)="showNext(1)"
                  class="btn btn-secondary nav-button fw-bold mb-0"
                  [disabled]="noMembershipsSelected"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <!--Review Payment-->
        <ng-container *ngIf="currentStep == 1">
          <div class="review-panel p-lg-0 ng-star-inserted">
            <div class="d-flex justify-content-center align-items-center mb-4 mt-lg-3 d-lg-none w-100">
              <img alt="" src="assets/images/placeholder.jpg" class="kid-logo w-100" />
            </div>
            <h2 class="text-lg-center review-panel-heading my-lg-0 py-lg-3 px-4">
              {{ 'kids.membership.upgrade.review.label' | translate }}
            </h2>
            <div class="row review-panel-row my-4 px-4">
              <div class="col-lg-7">
                <p
                  class="font-size-14"
                  [innerHtml]="'kids.membership.upgrade.review.description' | translate | markdownTranslate"
                >
                  {{ 'kids.membership.upgrade.review.description' | translate }}
                </p>
                <hr class="border-info w-100 hr-items mt-1" />
                <div>
                  <div class="text-black my-2">
                    <div *ngFor="let child of childUpgrades">
                      <ng-container *ngIf="child.Product.ProductId > 0">
                        <div class="row my-1">
                          <div class="col-12 font-size-14">
                            <span class="fw-bold">{{ child.FirstName }} {{ child.LastName }}</span>
                            -
                            <span>{{ child.Product.DisplayName }}</span>
                          </div>
                          <div class="col-12 font-size-12">
                            <span class="fw-bold">{{ 'kids.membership.upgrade.product.cost.label' | translate }}</span>
                            <span class="ms-2">{{ child.Product.MerchantPrice / 100 | FortressCurrencyPipe }}</span>
                          </div>
                        </div>
                        <hr class="border-info w-100 hr-items" />
                      </ng-container>
                    </div>
                  </div>
                </div>
                <button class="btn text-primary fw-bold d-none d-lg-block ps-0" (click)="showPrevious(0)">
                  <span class="font-size-14">Back</span>
                </button>
              </div>

              <!-- Right Side -->
              <div [ngClass]="{ 'd-none': editingBillingAddress || editingShippingAddress }" class="col-lg-5">
                <div class="position-relative rounded shadow p-3 mb-4">
                  <div class="top-right">
                    <a class="cursor-pointer" (click)="editBillingAddress()">
                      <span class="font-size-14 text-black">Edit</span>
                    </a>
                  </div>
                  <h6 class="mb-3 text-primary address-title font-size-18">Billing Address</h6>
                  <div class="mb-0 text-info font-size-14">
                    {{ billingAddress.Street }}, {{ billingAddress.Town }}, {{ billingAddress.County }},
                    {{ billingAddress.Postcode }}, {{ billingAddress.Country }}
                  </div>
                </div>
                <div class="position-relative rounded shadow p-3">
                  <div class="top-right">
                    <a class="cursor-pointer" (click)="editShippingAddress()">
                      <span class="font-size-14 text-black">Edit</span>
                    </a>
                  </div>
                  <h6 class="mb-3 text-primary address-title font-size-18">Shipping Address</h6>
                  <div class="mb-0 text-info font-size-14">
                    {{ shippingAddress.Street }}, {{ shippingAddress.Town }}, {{ shippingAddress.County }},
                    {{ shippingAddress.Postcode }}, {{ shippingAddress.Country }}
                  </div>
                </div>

                <!-- Giftcode input -->
                <div class="input-group rounded my-4">
                  <input
                    type="text"
                    class="form-control border-info bg-muted gift-code-input font-size-14"
                    [(ngModel)]="giftCodeInput"
                    aria-describedby="gift-card"
                  />
                  <button
                    class="btn btn-info text-white heading-text-one gift-code-input font-size-14 fw-bold border-info"
                    type="button"
                    (click)="applyGiftCode()"
                    [disabled]="disableApplyGiftCodesButton"
                    id="gift-card"
                  >
                    {{ 'kids.membership.upgrade.gift.code' | translate }}
                  </button>
                </div>
                <fgbcl-errors></fgbcl-errors>

                <div class="rounded shadow p-3 my-2">
                  <h6 class="mb-3 font-size-18 text-primary address-title">
                    {{ 'kids.membership.upgrade.summary.label' | translate }}
                  </h6>
                  <div class="row mb-2">
                    <div class="col-9 font-size-12 fw-bold">Membership total:</div>
                    <div class="col-3 font-size-14 text-end">{{ totalAmount / 100 | FortressCurrencyPipe }}</div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-9 font-size-12 fw-bold">Shipping:</div>
                    <div class="col-3 font-size-14 text-end">{{ totalShipping / 100 | FortressCurrencyPipe }}</div>
                  </div>
                  <ng-container *ngIf="totalTax > 0">
                    <div class="row mb-2">
                      <div class="col-9 font-size-12 fw-bold">Tax:</div>
                      <div class="col-3 font-size-14 text-end">{{ totalTax / 100 | FortressCurrencyPipe }}</div>
                    </div>
                  </ng-container>
                  <div class="row mb-2" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
                    <div class="col-12 font-size-12 fw-bold">Discount Code(s):</div>
                    <ng-container *ngFor="let giftCode of appliedGiftCodes">
                      <div class="col-9 font-size-14">
                        <button (click)="removeGiftCode(giftCode.Code)">X</button> {{ giftCode.Code }}
                      </div>
                      <div class="col-3 font-size-14 text-end">
                        <span *ngIf="giftCode.EntitlementValueType == 0"
                          >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
                        >
                        <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
                        <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
                      </div>
                    </ng-container>
                  </div>
                  <hr class="my-3 bg-muted" />
                  <div class="row fw-bold font-size-14 mt-2">
                    <div class="col-8">{{ 'kids.membership.upgrade.payment.details.total' | translate }}</div>
                    <div class="col-4 text-end">
                      {{ totalBasketAmount / 100 | FortressCurrencyPipe }}
                    </div>
                  </div>
                </div>
                <!--Pay now button-->
                <button
                  class="btn btn-success fw-bold mt-4 w-100 font-size-14 d-lg-block d-none text-white"
                  (click)="showModalPayment(paymentDetails)"
                >
                  Proceed to Payment
                </button>
              </div>

              <!-- EDIT BILLING ADDRESS FORM -->
              <ng-container *ngIf="editingBillingAddress">
                <div class="col-lg-5">
                  <div class="position-relative rounded shadow p-3">
                    <div class="top-right text-primary fw-bold cursor-pointer font-size-12">
                      <a (click)="clearAddressForm(billingAddressForm)">Clear Form</a>
                    </div>
                    <h6 class="mb-3 text-primary address-title">Billing Address</h6>
                    <form [formGroup]="billingAddressForm" class="font-size-12">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="FirstName"
                          formControlName="FirstName"
                          placeholder="First Name"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.FirstName.errors
                          }"
                          required
                        />
                        <label for="FirstName">
                          First Name
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter your first name.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Surname"
                          formControlName="Surname"
                          placeholder="First Name"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Surname.errors
                          }"
                          required
                        />
                        <label for="Surname">
                          Last Name
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter your last name.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Street"
                          placeholder="Street"
                          name="Street"
                          formControlName="Street"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Street.errors
                          }"
                          required
                        />
                        <label for="Street">
                          Street
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid street.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Town"
                          name="Town"
                          placeholder="Town"
                          formControlName="Town"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Town.errors
                          }"
                          required
                        />
                        <label for="Town">
                          City
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid city.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="County"
                          name="County"
                          placeholder="County"
                          formControlName="County"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.County.errors
                          }"
                          required
                        />
                        <label for="County">
                          State
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid state.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Postcode"
                          name="Postcode"
                          placeholder="Postcode"
                          formControlName="Postcode"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Postcode.errors
                          }"
                          required
                        />
                        <label for="Postcode">
                          Zip Code
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid zip code.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Country"
                          name="Country"
                          placeholder="Country"
                          formControlName="Country"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Country.errors
                          }"
                          required
                        />
                        <label for="Country">
                          Country
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid country.</div>
                      </div>
                    </form>
                    <div class="d-flex justify-content-between mt-3 font-size-14">
                      <button class="btn btn-danger fw-bold btn-edit text-white" (click)="cancelAndRebuildForm('billing')">
                        Cancel
                      </button>
                      <button class="btn btn-success fw-bold btn-edit text-white" (click)="updateBillingAddress()">Save</button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="editingShippingAddress">
                <div class="col-lg-5">
                  <div class="position-relative rounded shadow p-3">
                    <div class="top-right text-primary fw-bold cursor-pointer font-size-12">
                      <a (click)="clearAddressForm(shippingAddressForm)">Clear Form</a>
                    </div>
                    <h6 class="mb-3 text-primary address-title">Shipping Address</h6>
                    <form [formGroup]="shippingAddressForm" class="font-size-12">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="FirstName"
                          formControlName="FirstName"
                          placeholder="First Name"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.FirstName.errors
                          }"
                          required
                        />
                        <label for="FirstName">
                          First Name
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter your first name.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Surname"
                          formControlName="Surname"
                          placeholder="First Name"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Surname.errors
                          }"
                          required
                        />
                        <label for="Surname">
                          Last Name
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter your last name.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Street"
                          placeholder="Street"
                          name="Street"
                          formControlName="Street"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Street.errors
                          }"
                          required
                        />
                        <label for="Street">
                          Street
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid street.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Town"
                          name="Town"
                          placeholder="Town"
                          formControlName="Town"
                          [ngClass]="{ 'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Town.errors }"
                          required
                        />
                        <label for="Town">
                          City
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid city.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="County"
                          name="County"
                          placeholder="County"
                          formControlName="County"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.County.errors
                          }"
                          required
                        />
                        <label for="County">
                          State
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid state.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Postcode"
                          name="Postcode"
                          placeholder="Postcode"
                          formControlName="Postcode"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Postcode.errors
                          }"
                          required
                        />
                        <label for="Postcode">
                          Zip Code
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid zip code.</div>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="Country"
                          name="Country"
                          placeholder="Country"
                          formControlName="Country"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Country.errors
                          }"
                          required
                        />
                        <label for="Country">
                          Country
                          <span class="text-danger">*</span>
                        </label>
                        <div class="invalid-feedback">Please enter a valid country.</div>
                      </div>
                    </form>
                    <div class="d-flex justify-content-between mt-3 font-size-14">
                      <button class="btn btn-danger fw-bold btn-edit text-white" (click)="cancelAndRebuildForm('shipping')">Cancel</button>
                      <button class="btn btn-success fw-bold btn-edit text-white" (click)="updateShippingAddress()">Save</button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="button-container px-4 mb-3 mb-lg-0 d-flex justify-content-between d-lg-none">
            <!-- Previous Button -->
            <button (click)="showPrevious(0)" class="btn btn-primary nav-button fw-bold mb-0">
              Back
              <ng-template #noPreviousStep>Back</ng-template>
            </button>

            <!-- Next Button -->
            <button (click)="showNext(2)" class="btn btn-success nav-button fw-bold mb-lg-0 text-white">Payment</button>
          </div>
        </ng-container>

        <!--Payment Process-->
        <ng-container *ngIf="currentStep == 2">
          <ng-container *ngTemplateOutlet="paymentDetails"></ng-container>
        </ng-container>

        <ng-container *ngIf="orderCompleted">
          <div class="d-flex flex-row h-100 completed-screen">
            <div class="col-lg-5 d-none d-lg-block login-banner"></div>
            <div class="col-lg-7 px-lg-5 px-3 d-flex flex-column justify-content-between">
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-center align-items-center my-2 d-lg-none">
                  <img alt="" src="assets/images/kidnationnewlogo.svg" class="kid-logo" />
                </div>
                <h2 class="text-lg-start text-center text-success my-lg-3 mb-4">{{ 'purchase.success.title' | translate }}</h2>
                <p class="font-size-14 mb-5">
                  {{ 'purchase.success.description' | translate }}
                  <span class="text-primary fw-bold">{{ 'purchase.success.description.email' | translate }}</span
                  ><strong>{{ 'purchase.success.description.number' | translate }}</strong>
                </p>
                <p class="font-size-14 text-start fw-bold mb-lg-3 mb-2">
                  {{ 'kids.membership.upgrade.summary.label' | translate }}
                </p>
                <hr class="border-info w-100 hr-items mt-1" />
                <div class="font-size-12">
                  <div class="text-black my-2">
                    <div *ngFor="let child of childUpgrades">
                      <ng-container *ngIf="child.Product.ProductId > 0">
                        <div class="row my-1">
                          <div class="col-12 mb-2">
                            <span class="fw-bold">{{ child.FirstName }} {{ child.LastName }}</span>
                            -
                            {{ child.Product.DisplayName }}
                          </div>
                          <div class="col-12">
                            <span class="fw-bold">{{ 'kids.membership.upgrade.product.cost.label' | translate }}</span>
                            {{ child.Product.MerchantPrice / 100 | FortressCurrencyPipe }}
                          </div>
                        </div>
                        <hr class="border-info w-100 hr-items" />
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-end font-size-14 mt-2">
                  <span class="fw-bold">{{ 'purchase.success.total.label' | translate }}</span
                  >{{ totalBasketAmount / 100 | FortressCurrencyPipe }}
                </div>
              </div>
              <div class="w-100 d-flex justify-content-center">
                <button class="btn btn-primary w-80 my-lg-4 my-3 font-size-14 fw-bold" (click)="CloseModal()">
                  {{ 'kids.membership.upgrade.button.completed' | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #paymentDetails>
  <div class="d-flex flex-column w-100 w-lg-50">
    <div class="d-flex flex-row text-primary align-items-center justify-content-center remove-outline p-3">
      <div class="d-flex flex-row align-items-center">
        <span class="icon material-icons me-2 font-size-24" aria-hidden="true">add_card</span>
        <h5 class="text-start m-0 font-size-18">{{ 'kids.membership.upgrade.payment.details.title' | translate }}</h5>
      </div>
    </div>
    <div class="mx-3 mb-5">
      <hr class="my-1 bg-muted" />
      <!-- Summary -->
      <div class="my-4">
        <div class="row text-black font-size-14 mx-lg-5 mb-5">
          <div class="col-9">{{ 'kids.membership.upgrade.payment.details.total' | translate }}</div>
          <div class="col-3 fw-bold">{{ totalBasketAmount / 100 | FortressCurrencyPipe }}</div>
        </div>
        <!-- Payment Provider Form -->
        <div *ngIf="loadingPaymentForm">
          <fgbcl-loading></fgbcl-loading>
        </div>
        <div id="paymentDiv" class="mb-4"></div>
      </div>
    </div>
    <div class="button-container mb-3 mb-lg-0 d-flex justify-content-center">
      <!-- Previous Button -->
      <button (click)="showPrevious(1)" class="btn btn-primary nav-button fw-bold mb-0">Cancel</button>
    </div>
  </div>
</ng-template>
