<div *ngIf="amounts$ | async as amounts" class="card">
  <div class="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom-0 pe-2">
    <div class="float-start ecash-load-heading text-primary fw-bold">LOAD ECASH</div>
    <button
      class="float-end btn p-0 text-primary fw-bold"
      (click)="open(manualtopUpModal)"
      attr.aria-label="{{ 'ecash.open.topup.modal.screenreader' | translate }}"
    >
      <span class="material-icons align-middle" aria-hidden="true">info</span>
    </button>
  </div>
  <div class="card-body pt-1">
    <div class="mb-1 label ecash-load-sub-heading fw-bold">Load Amount</div>
    <div class="row">
      <div class="col-6 mb-3 mb-lg-0">
        <select class="form-control" (change)="setAmount($event.target.value)">
          <option *ngFor="let topUpAmount of amounts" [value]="topUpAmount">
            {{ topUpAmount / 100 | FortressCurrencyPipe : 'USD' }}
          </option>
        </select>
      </div>
      <div class="col-6">
        <button class="btn btn-block fw-bold btn-success" (click)="open(confirm)">Top Up</button>
      </div>
    </div>
  </div>
</div>

<ng-template #confirm let-modal>
  <div class="modal-content">
    <div class="modal-body text-center p-2">
      <div class="modal-header d-block text-center border-bottom-0">
        <div class="drinks-icon icon svg svg-primary mb-2 mx-auto" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
        <h3 class="modal-heading text-primary">Load ecash</h3>
        <div class="my-4 sub-heading-modal fw-bold">
          Are you sure you want to load
          <span class="text-success fw-bolder"> {{ amount / 100 | FortressCurrencyPipe : 'USD' }}</span> to your account?
        </div>
      </div>
      <div id="paymentDiv" class="payment-modal mt-4 mx-auto"></div>
      <div class="btn-container mt-5 d-block">
        <button type="button" class="btn btn-danger m-2 btn-text fw-bold" (click)="modal.dismiss()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #manualtopUpModal let-modal>
  <div class="info-modal">
    <div class="bg-primary text-white">
      <div class="modal-header d-block text-center border-bottom-0 mb-3">
        <div class="drinks-icon icon svg svg-white mb-3 mt-2 mx-auto" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
        <h3 class="modal-heading">How to load manually ?</h3>
        <div class="close-container me-3 ms-auto position-absolute">
          <div class="icon material-icons align-middle" (click)="modal.dismiss('close')"></div>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <p>Top-up your membership card, with a once off amount of eCash.</p>
          <p><span class="sub-heading fw-bold">Step 1.</span> Enter the desire amount you wish top up with in “Load amount”.</p>
          <p><span class="sub-heading fw-bold">Step 2.</span> Click the green top-up button.</p>
          <p>
            <span class="sub-heading fw-bold">Step 3.</span> Enter your credit card or debit card details in the fields required.
          </p>
          <p><span class="sub-heading fw-bold">Step 4.</span> Click submit.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
