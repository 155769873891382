import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  config,
  MemberDetails,
  MemberQuery,
  BalanceQuery,
  MemberCard,
  MemberCardQuery,
  Card,
  CardQuery,
  CardService,
  SportsAllianceAuthService,
} from '@fgb/core';
import { Observable, Subject } from 'rxjs';
import { tap, map, takeUntil } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
import { ProductCode } from 'src/app/shared/utilities/product-code';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  populations = Populations;
  productCode = ProductCode;
  memberDetails$: Observable<MemberDetails | undefined>;
  combinedCard$: Observable<number | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;
  card$: Observable<MemberCard | undefined>;
  isCollapsed = false;
  name$: Observable<string>;
  selectedMember: string;
  cards$: Observable<Card[]>;
  currentLanguage: string = '';
  private _destroyed$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public router: Router,
    public cardService: CardService,
    public memberQuery: MemberQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private cardQuery: CardQuery,
    private sportsAllianceService: SportsAllianceAuthService
  ) {}

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectObservedMemberCard();

    this.initAccountSwitch();
    this.initLcid();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  initAccountSwitch() {
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName} ${m.Surname}`;
        }
        return '';
      })
    );

    this.selectedMember = this.memberQuery.getUserContext().memberId;
    this.cards$ = this.cardQuery.selectCards().pipe(
      map((cards: Card[]) =>
        cards
          .sort((a, b) => parseInt(a.Seat) - parseInt(b.Seat))
          .sort((a, b) => parseInt(a.Row) - parseInt(b.Row))
          .sort((a, b) => parseInt(a.Area) - parseInt(b.Area))
      )
    );
  }

  initLcid() {
    this.memberQuery.userContext$.pipe(takeUntil(this._destroyed$)).subscribe((user) => {
      if (user && user.lcid) {
        if (user.lcid === '2057') {
          this.currentLanguage = 'EN';
        }
      }
    });
  }

  logout() {
    this.sportsAllianceService
      .logout()
      .pipe(tap(() => this.router.navigate(['/login'])))
      .toPromise();
  }

  closeDropDown(dropdown: NgbDropdown) {
    dropdown.close();
  }
}
