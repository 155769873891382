<div class="card">
  <div class="card-body row font-size-14 px-3 py-2">
    <h5 *ngIf="claimLimit; else noLimit">
      YOU HAVE <span class="benefits-header-claimed mx-2">{{ numberClaimed }}/{{ claimLimit }}</span> CHOICE BENEFITS
    </h5>
    <ng-template #noLimit><h4>YOU HAVE UNLIMITED CHOICE BENEFITS</h4></ng-template>
    <p class="mb-0 benefits-header-description">
      As a big member, we are providing you with the opportunity to customize your membership!
    </p>
    <p class="mb-0 benefits-header-description">Choose your benefits from the list below</p>
  </div>
</div>
