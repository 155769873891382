<nav class="navbar bg-primary text-white navbar-expand-lg d-none d-lg-flex shadow px-3">
  <a
    class="navbar-brand py-0"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    attr.aria-label="{{ 'nav.home.page.screenreader' | translate : { fgbdefault: 'Move to home page' } }}"
  >
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <img alt="" class="ms-1 h-100" aria-labelledby="homeLink" src="/assets/images/logo.svg" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav" #greedyNav>
        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/rewards']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.rewards.screenreader' | translate : { fgbdefault: 'Move to rewards page' } }}"
            >{{ 'nav.rewards' | translate }}</a
          >
        </li>

        <ng-container *notInProductCode="[productCode.Free]">
          <li class="nav-item active">
            <a
              class="nav-link mx-1"
              [routerLink]="['/challenges']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.challenges.screenreader' | translate : { fgbdefault: 'Move to challenges page' } }}"
              >{{ 'nav.challenges' | translate }}</a
            >
          </li>
        </ng-container>

        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/games/quiz']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.games.screenreader' | translate : { fgbdefault: 'Move to games page' } }}"
            >{{ 'nav.games' | translate }}</a
          >
        </li>

        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/earn']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.earn.screenreader' | translate : { fgbdefault: 'Move to earn page' } }}"
            >{{ 'nav.earn' | translate }}</a
          >
        </li>

        <li class="nav-item active help-dropdown static-nav-item" focusTab #helpBtn>
          <div ngbDropdown #ddHelp="ngbDropdown" [autoClose]="'outside'" role="group">
            <a
              class="nav-link mx-1"
              id="helpdropdown"
              ngbDropdownToggle
              focusTabBtn
              attr.aria-label="{{ 'nav.help.screenreader' | translate : { fgbdefault: 'Click for help pages' } }}"
            >
              <span>{{ 'nav.help' | translate }}</span>
            </a>
            <ul ngbDropdownMenu (click)="closeDropDown(ddHelp)" aria-labelledby="helpdropdown">
              <li class="nav-item active">
                <a
                  class="nav-link mx-1"
                  [routerLink]="['/faq']"
                  routerLinkActive="router-link-active"
                  attr.aria-label="{{ 'nav.faq.screenreader' | translate : { fgbdefault: 'Move to faq page' } }}"
                  >{{ 'nav.faq' | translate }}</a
                >
              </li>
              <li class="nav-item active">
                <a
                  class="nav-link mx-1"
                  [routerLink]="['/terms']"
                  routerLinkActive="router-link-active"
                  attr.aria-label="{{ 'nav.terms.screenreader' | translate : { fgbdefault: 'Move to terms page' } }}"
                  >{{ 'nav.terms' | translate }}</a
                >
              </li>
              <li class="nav-item active">
                <a
                  class="nav-link mx-1"
                  [routerLink]="['/contact']"
                  routerLinkActive="router-link-active"
                  attr.aria-label="{{ 'nav.contact.us.screenreader' | translate : { fgbdefault: 'Move to contact us page' } }}"
                  >{{ 'nav.contact.us' | translate }}</a
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="d-flex align-items-center flex-nowrap my-2 my-lg-0">
      <div class="balance font-size-14">
        <div class="d-flex align-items-center" *ngIf="loyaltyPointsCard$">
          <span
            class="icon material-icons currency-icons pe-1"
            role="img"
            attr.aria-label="{{ 'nav.points.screenreader' | translate }}"
            >emoji_events</span
          >
          <span class="fw-bold">{{ loyaltyPointsCard$ | async | FortressCurrencyPipe : 'PTS' }}</span>
        </div>
      </div>
      <!-- <div class="balance font-size-14 text-success ps-0">
        <div class="d-flex align-items-center" *ngIf="combinedCard$">
          <span
            class="icon material-icons currency-icons me-2"
            role="img"
            attr.aria-label="{{ 'nav.ecash.screenreader' | translate }}"
            >paid</span
          >
          <span class="fw-bold">{{ combinedCard$ | async | FortressCurrencyPipe }}</span>
        </div>
      </div> -->
      <!--Notifications-->
      <fgb-notification-button></fgb-notification-button>
      <!--New Dropdown -->
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn d-flex align-items-center mx-2 px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate : { fgbdefault: 'Click for more account menu' } }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-start fw-bold text-capitalize">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-center border-0 dropdown-menu p-0 account-dropdown mt-2"
        >
          <div class="text-info">
            <a
              [routerLink]="['/account']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.account.screenreader' | translate : { fgbdefault: 'Move to account page' } }}"
              class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 font-size-14 nav-item-fixed-height rounded-top"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">person</span>
              <div class="dropdown-link text-start">
                <div class="mt-2 fw-bold">Account</div>
                <div class="nav-bar-little-text text-start text-info" *ngIf="card$ | async as memberCard">
                  {{ memberCard.ProductCode }}
                </div>
              </div>
            </a>
          </div>
          <ng-container *notInProductCode="[productCode.Free]">
            <hr class="text-disabled w-90 mx-auto my-0" />

            <div class="text-black">
              <a
                [routerLink]="['/rewards/wallet']"
                routerLinkActive="router-link-active"
                attr.aria-label="{{ 'nav.wallet.screenreader' | translate : { fgbdefault: 'Move to wallet page' } }}"
                class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height"
                ngbDropdownItem
              >
                <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">account_balance_wallet</span>
                <div class="dropdown-link text-start">
                  <div class="font-size-14 fw-bold">{{ 'nav.wallet' | translate }}</div>
                </div>
              </a>
            </div>
          </ng-container>

          <hr class="text-disabled w-90 mx-auto my-0" />
          <div>
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
              attr.aria-label="{{ 'nav.log.out.screenreader' | translate : { fgbdefault: 'Log out' } }}"
            >
              <span class="icon material-icons material-dropdown-icon me-2 text-danger" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link text-start">
                <div class="text-danger font-size-14 fw-bold" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate : { fgbdefault: 'Log out' } }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
