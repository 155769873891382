<div class="lost-modal d-flex flex-column justify-content-between">
  <ng-container *ngIf="!replacementSuccess">
    <div class="pt-3 px-4 text-center">
      <h3 class="text-primary">
        <ng-container *ngIf="isBarcodeLost"> {{ 'replace.child.barcode' | translate }} </ng-container
        ><ng-container *ngIf="!isBarcodeLost">{{ 'attach.child.barcode' | translate }}</ng-container>
      </h3>
      <p class="mb-2 fw-bold mb-0 my-4">
        <ng-container *ngIf="isBarcodeLost"> {{ 'replace.child.barcode.description' | translate }}</ng-container>
        <ng-container *ngIf="!isBarcodeLost">{{ 'attach.child.barcode.description' | translate }}</ng-container>
      </p>
      <hr class="bg-muted mx-4 mb-lg-5 mb-2" />
      <div class="d-flex">
        <form [formGroup]="barcodeForm" class="text-start mx-auto">
          <label>{{ 'lost.card.barcode' | translate }}</label>
          <br />
          <input
            class="border-0 rounded"
            formControlName="barcode"
            id="barcode"
            attr.placeholder="{{ 'lost.card.barcode.placeholder' | translate }}"
          />
          <fgb-errors></fgb-errors>
        </form>
      </div>
    </div>

    <div class="modal-footer pt-2 d-flex justify-content-between border-0">
      <button type="button" class="btn btn-primary fw-bold font-size-14" (click)="activeModal.close('Close click')">
        {{ 'lost.card.button.cancel' | translate }}
      </button>
      <button type="button" class="btn fw-bold font-size-14 btn-success" (click)="linkNewBarcode()">
        {{ 'lost.card.button.confirm' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="replacementSuccess">
    <div class="text-center pt-3 px-4">
      <h3 class="text-success">{{ 'update.child.barcode.heading' | translate }}</h3>
      <p class="fw-bold mb-0 my-4">{{ 'update.child.barcode.description' | translate }}</p>
      <hr class="bg-muted mx-4" />

      <div class="material-icons align-middle text-success mt-5">check_circle</div>
    </div>

    <div class="modal-footer pt-2 d-flex justify-content-around border-0">
      <button type="button" class="btn btn-primary fw-bold font-size-14" (click)="closeModalAndUpdate()">
        {{ 'lost.card.button.close' | translate }}
      </button>
    </div>
  </ng-container>
</div>
