<div class="bg-tertiary upgrade-card">
  <div class="upgrade-section container">
    <div class="bg-primary upgrade-image me-lg-4 mt-4 mt-lg-0" *inProductCode="productCode.Free">
      <img class="h-100" src="assets/images/memberships/evergreen-lite.png" draggable="false" />
    </div>

    <ng-container *inProductCode="[productCode.Tier1, productCode.Tier2]">
      <ng-container *ifScreenSize="screenSize.Desktop">
        <div class="d-flex">
          <img class="" src="assets/images/memberships/standard-membership.png" draggable="false" />

          <img class="mx-3" src="assets/images/memberships/premium-membership.png" draggable="false" />
        </div>
      </ng-container>
      <ng-container *ifScreenSize="screenSize.Mobile">
        <div class="d-flex my-3">
          <img class="me-2" src="assets/images/memberships/mobile-standard.png" draggable="false" />

          <img class="ms-2" src="assets/images/memberships/mobile-premium.png" draggable="false" />
        </div>
      </ng-container>
    </ng-container>

    <ng-container *inProductCode="[productCode.Tier3]">
      <ng-container *ifScreenSize="screenSize.Desktop">
        <div class="d-flex">
          <img class="me-3" src="assets/images/memberships/premium-membership.png" draggable="false" />
        </div>
      </ng-container>
      <ng-container *ifScreenSize="screenSize.Mobile">
        <div class="d-flex my-3">
          <img class="" src="assets/images/memberships/mobile-premium.png" draggable="false" />
        </div>
      </ng-container>
    </ng-container>

    <div class="d-flex upgrade-description">
      <h5 class="text-secondary">{{ 'upgrade.membership.title' | translate }}</h5>
      <div class="upgrade-description-text font-size-14 me-lg-2">{{ 'upgrade.membership.description' | translate }}</div>
    </div>

    <ng-container *inProductCode="[productCode.Free]">
      <div [routerLink]="['/account']" class="btn btn-primary text-tertiary fw-bold">
        {{ 'upgrade.membership.button' | translate }}
      </div>
    </ng-container>
    <ng-container *notInProductCode="[productCode.Free]">
      <a href="{{ 'upgrade.button.url' | translate }}" target="_blank" class="btn btn-primary text-tertiary fw-bold">
        {{ 'upgrade.membership.button.upgrade' | translate }}
      </a>
    </ng-container>
  </div>
</div>
