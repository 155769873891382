<div class="survey-answer answer-type-{{ displayType }}">
  <div
    class="survey-answer-container flex-column w-100"
    [class.align-items-center]="hasSelectedCorrect"
    [ngClass]="hasSelectedCorrect ? 'justify-content-center' : 'justify-content-around'"
  >
    <ng-container [ngSwitch]="displayType">
      <!-- Image and Text -->
      <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
        <div class="d-flex align-items-center w-100 image-text-container">
          <div
            class="survey-answer-item m-2"
            [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
            *ngFor="let answer of selectedAnswers"
          >
            <div class="image-container">
              <img src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
            </div>
            <div class="answer-text text-center text-white">
              <span>{{ answer.Title }}</span>
            </div>
          </div>
          <div class="survey-answer-item correct m-2" *ngIf="!hasSelectedCorrect">
            <div class="image-container">
              <img src="{{ correctAnswer.ImageURL | contentImage : '7' }}" alt="{{ correctAnswer.ImageURL }}" />
            </div>
            <div class="answer-text text-center text-center text-white">
              <span>{{ correctAnswer.Title }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Image Only -->
      <ng-container *ngSwitchCase="answerDisplayType.Image">
        <div
          class="survey-answer-item m-2"
          [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
          *ngFor="let answer of selectedAnswers"
        >
          <div class="image-container">
            <img src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
          </div>
        </div>
        <div class="survey-answer-item correct m-2" *ngIf="!hasSelectedCorrect">
          <div class="image-container">
            <img src="{{ correctAnswer.ImageURL | contentImage : '7' }}" alt="{{ correctAnswer.ImageURL }}" />
          </div>
        </div>
      </ng-container>
      <!-- Text -->
      <ng-container *ngSwitchCase="answerDisplayType.Text">
        <div
          class="survey-answer-item m-2 d-flex flex-grow-1"
          [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
          *ngFor="let answer of selectedAnswers"
        >
          <div class="answer-text text-center text-white">
            <span>{{ answer.Title }}</span>
          </div>
        </div>
        <div class="survey-answer-item m-2 d-flex flex-grow-1" *ngIf="!hasSelectedCorrect">
          <div class="answer-text text-center text-center text-white bg-success">
            <span>{{ correctAnswer.Title }}</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="">
      <ng-container *ngIf="hasSelectedCorrect; else incorrect">
        <ng-container *ngIf="pointsWon; else noPoints">
          <div class="text-center text-success fw-bold font-size-14 mt-3">
            {{ 'survey.predictor.correct.title' | translate }}
          </div>

          <div class="text-center text-success font-size-14 fw-bold mt-1">
            +{{ pointsWon / 100 | FortressCurrencyPipe : 'PTS' }}
          </div>
        </ng-container>
        <ng-template #noPoints>
          <div class="fw-bold text-success font-size-12 mt-3">
            {{ 'survey.predictor.correct.title' | translate }}
          </div>
        </ng-template>
      </ng-container>
      <ng-template #incorrect>
        <div class="text-center fw-bold font-size-14 mt-3 text-danger">
          {{ 'survey.predictor.incorrect.title' | translate }}
        </div>
        <div class="text-center font-size-14 mt-2">
          {{ 'survey.predictor.incorrect.message' | translate }}
        </div>
      </ng-template>
    </div>
  </div>
</div>
