<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/login-registration/login-banner.png" />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->

  <div class="right-side-login">
    <div class="right-side-container">
      <div
        class="panel-text font-size-14 mb-4 fw-bold text-center"
        [innerHTML]="'login.page.description' | translate | markdownTranslate"
      ></div>
      <fgb-sports-alliance-user-login></fgb-sports-alliance-user-login>
    </div>
  </div>
</div>
