<div class="container d-flex align-items-center">
  <div class="card mx-auto mt-lg-0 mt-5 align-self-start align-self-lg-center">
    <div class="card-body text-center py-3">
      <div class="card-heading">
        <h5 class="success-title mb-0 text-success">{{ 'portal.product.success.page.title' | translate }}</h5>
      </div>
      <div class="mt-4 sub-heading-modal">
        <div class="portal-product-info">
          <div class="img-container bg-primary">
            <img [src]="cartItem?.productData?.PrimaryImage | contentImage : '6'" alt="" />
          </div>
          <div class="product-description">
            <div class="product-name">{{ cartItem?.productData?.DisplayName }}</div>
            <div class="product-price">
              <label class="me-2">{{ 'portal.product.success.page.price.label' | translate }}</label>
              <span class="product-total-price font-size-12">{{
                cartItem?.productData?.MerchantPrice / 100 | FortressCurrencyPipe : 'GBP'
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container mt-2 d-block">
        <button (click)="updateAccount()" type="button" class="btn btn-primary text-white mx-2 mt-4 fw-bold">
          {{ 'portal.product.success.page.close.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
