import { Component } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-upgrade-section',
  templateUrl: './upgrade-section.component.html',
  styleUrl: './upgrade-section.component.scss',
})
export class UpgradeSectionComponent {
  productCode = ProductCode;
  screenSize = ScreenType;
}
