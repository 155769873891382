<div class="banner" role="banner">
  <h1>Guardian</h1>
</div>

<div class="container mt-4">
  <ng-container *ngIf="isGuardian; else notGuardian">
    <div>
      <fgb-child-list></fgb-child-list>
    </div>
    <div class="rounded shadow overflow-hidden guardian-info">
      <div class="row">
        <div class="col-lg-6 col-12">
          <img alt="" class="w-100 h-100" src="assets/images/background.jpg" />
        </div>
        <div class="col-lg-6 col-12">
          <div class="mt-3 pe-lg-2 ps-lg-0 px-4">
            <h3 class="font-size-14">The official Kids club of Fortress!</h3>
            <p class="font-size-14 mt-4">
              The Fortress Kids Club, is the best way to show your Fortress pride! Sign up today and enjoy exclusive kids-only
              offers and unique experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #notGuardian>
    <div class="text-start my-2">
      <div class="shadow rounded overflow-hidden guardian-info text-start my-2">
        <div class="row">
          <div class="col-lg-6 col-12">
            <img alt="" class="w-100 h-100" src="assets/images/background.jpg" />
          </div>
          <div class="col-lg-6 col-12">
            <div class="mt-3 pe-lg-2 ps-lg-0 px-4">
              <h3 class="font-size-14">The official Kids club of Fortress</h3>
              <p class="font-size-14 mt-4">
                The Fortress Kids Club, is the best way to show your Fortress pride! Sign up today and enjoy exclusive kids-only
                offers and unique experiences.
              </p>
              <button
                (click)="becomeGuardian()"
                class="btn btn-primary btn-guardian font-size-14 text-center text-white mx-auto mt-4 mt-lg-5 mb-4 p-0 w-100 fw-bold"
              >
                Click Here to Register as a Guardian
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-2">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="membership-panel rounded overflow-hidden shadow mt-lg-4 mt-3">
            <div class="membership-body text-white pt-3 pb-3 px-2">
              <h2 class="mb-4">Free</h2>
              <h4>Basic Membership</h4>
              <div class="mt-3 text-uppercase font-size-16 fw-bold">available for all fans ages 12 and under!</div>
            </div>
            <div class="membership-dropdown">
              <div class="w-100 d-flex justify-content-between my-2 px-2 align-items-center">
                <div class="membership-include-text fw-bold text-primary">Basic Membership Benefits Include:</div>
                <button
                  type="button"
                  class="btn"
                  (click)="freeCollapse.toggle()"
                  [attr.aria-expanded]="!isFreeCollapsed"
                  aria-controls="freeCollapse"
                >
                  <div class="dropdown-icon kid-dropdown svg mt-0" [inlineSVG]="'assets/images/icons/down-icon.svg'"></div>
                </button>
              </div>
              <div #freeCollapse="ngbCollapse" [(ngbCollapse)]="isFreeCollapsed">
                <div class="font-size-12 text-start p-2">
                  - Membership card and lanyard<br />
                  - Play games in the Fortress Portal<br />
                  - Exclusive email offers<br />

                  *Offers subject to change
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="membership-panel rounded overflow-hidden shadow mt-lg-4 mt-3">
            <div class="membership-body mvp text-white pt-3 pb-3 px-2">
              <h2 class="mb-4">$20</h2>
              <h4>King membership</h4>
              <div class="mt-3 text-uppercase font-size-16 fw-bold">available to all fans ages 4-12!</div>
            </div>
            <div class="membership-dropdown">
              <div class="w-100 d-flex justify-content-between my-2 px-2 align-items-center">
                <div class="membership-include-text fw-bold text-primary">King Membership Benefits Include:</div>
                <button
                  type="button"
                  class="btn"
                  (click)="kingCollapse.toggle()"
                  [attr.aria-expanded]="!isKingCollapsed"
                  aria-controls="freeCollapse"
                >
                  <div class="dropdown-icon kid-dropdown svg mt-0" [inlineSVG]="'assets/images/icons/down-icon.svg'"></div>
                </button>
              </div>
              <div #kingCollapse="ngbCollapse" [(ngbCollapse)]="isKingCollapsed">
                <div class="font-size-12 text-start p-2">
                  - Membership card and lanyard<br />
                  - Play games in the Fortress Portal<br />
                  - Exclusive email offers<br />
                  - 2020 Fortress yearbook<br />

                  *Offers subject to change
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
