<div class="modal-content text-primary border-0">
  <div class="col-12 px-0">
    <owl-carousel-o [options]="customOptions" #owlCar>
      <ng-container *ngFor="let child of children">
        <ng-template carouselSlide [id]="child.MemberId">
          <div class="col-12 full-container">
            <div class="barcode-container rounded-top bg-white text-center mx-auto">
              <div class="close-container me-3 ms-auto position-relative">
                <div class="icon material-icons align-middle text-primary pt-2 cursor-pointer" (click)="closeModal()"></div>
              </div>
              <div class="qr px-2 bg-white">
                <qrcode [qrdata]="child.CardBarcode" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
              </div>
              <p class="text-primary fw-bold child-name mt-3 mb-0 text-uppercase">{{ child.Firstname }} {{ child.Lastname }}</p>
              <p class="font-size-14 text-black">Rookie</p>
            </div>
            <div class="barcode-info rounded-bottom bg-secondary mx-auto pt-4">
              <div class="d-flex justify-content-between">
                <div class="ms-5 text-center">
                  <p class="text-white mb-0">Account No.</p>
                  <p class="text-primary">{{ child.ExternalRefNumber }}</p>
                </div>
                <div class="me-5 text-center">
                  <p class="text-white mb-0">Member ID</p>
                  <p class="text-primary">{{ child.MemberId }}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
