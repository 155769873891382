<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1>{{ 'wallet.page.title' | translate }}</h1>
  </div>
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<div class="container d-lg-none d-block">
  <div class="mt-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<!-- V2 Wallet -->
<ng-container
  *ngIf="
    {
      walletItemsUnclaimed: walletItemsUnclaimed$ | async
    } as walletItems;
    else noWalletItems
  "
>
  <div class="wallet-redeem-carousel pt-4">
    <div class="container">
      <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems.walletItemsUnclaimed"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mt-2 mb-3">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border rounded">
      <div class="no-wallet-items-description fw-bold font-size-12 text-center text-grey py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text fw-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>
