import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  PortalProduct,
  PortalProductsQuery,
  PortalProductsSubscriptionsQuery,
  PortalProductSubscription,
  Cart,
  CartQuery,
  CartService,
  PortalProductsService,
  PortalProductsSubscriptionsService,
} from '@fgb/core';
import { Observable, Subscription } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrls: ['./subscriptions-list.component.scss'],
})
export class SubscriptionsListComponent implements OnInit, OnDestroy {
  productTypes: number[] = [1, 3];
  membershipPortalProducts: PortalProduct[];
  subscriptionPortalProducts: PortalProduct[];
  maxSubs: number;
  activeSubs: number;
  purchasedProducts: PortalProductSubscription[];
  foundMembership: boolean = false;
  foundSubscription: boolean = false;
  notPurchasedActiveSubs: PortalProduct[] = [];
  individualProduct: PortalProduct;
  purchasedSubProductType: number[] = [3];
  purchasedSubProducts: PortalProductSubscription[];
  allProducts: number;
  // modal sticky basket
  items$: Observable<Cart[]>;
  totalCost$: Observable<number>;
  listIdSubscription: string[];
  subscriptions$: Subscription;
  productCode = ProductCode;
  constructor(
    private portalProductsQuery: PortalProductsQuery,
    private portalProductsSubscriptionsQuery: PortalProductsSubscriptionsQuery,
    private cartQuery: CartQuery,
    private cartService: CartService,
    private portalProductsService: PortalProductsService,
    private portalProductsSubscription: PortalProductsSubscriptionsService
  ) {}

  isSubscriptionSelected(id: string) {
    return this.listIdSubscription?.includes(id);
  }

  getListIdSubscriptionItemInStore() {
    this.subscriptions$ = this.cartQuery.selectAllSubscriptionCartData().subscribe((x) => {
      this.listIdSubscription = x.map((x: any) => x.productData.ProductId);
    });
  }
  ngOnInit() {
    this.portalProductsSubscription.fetchSubscriptions().toPromise();
    this.portalProductsService
      .fetchPortalProducts()
      .toPromise()
      .then((_) => {
        this.membershipPortalProducts = this.portalProductsQuery.getAllProductsByProductType(this.productTypes[0]);
        this.subscriptionPortalProducts = this.portalProductsQuery.getAllProductsByProductType(this.productTypes[1]);

        this.portalProductsSubscriptionsQuery
          .selectSubscriptionsByProductTypes(this.productTypes)
          .pipe(
            tap((items) => {
              this.purchasedProducts = items;
              this.findExistingMembership();
              this.findExistingSubs();
            })
          )
          .toPromise();

        this.portalProductsSubscriptionsQuery
          .selectSubscriptionsByProductTypes(this.purchasedSubProductType)
          .pipe(take(1))
          .subscribe((subItems) => {
            this.purchasedSubProducts = subItems;
            this.getActiveNonPurchasedSubs();

            // active and maximum subs count
            this.activeSubs = this.purchasedSubProducts.length;
            this.maxSubs = this.notPurchasedActiveSubs.length + this.purchasedSubProducts.length;
          });

        this.allProducts =
          this.notPurchasedActiveSubs.length +
          this.purchasedProducts.length +
          this.portalProductsQuery.getAllProductsByProductType(this.productTypes[0], undefined).length;

        // modal basket item count and total cost
        this.items$ = this.cartQuery.selectAllSubscriptionCartData();
        this.items$
          .pipe(
            tap((_) => {
              this.getListIdSubscriptionItemInStore();
            })
          )
          .toPromise();
        this.totalCost$ = this.cartQuery.calculateTotalCost();
      });
  }

  // check to see if there is a product type 1 in the purchased products list
  findExistingMembership() {
    this.purchasedProducts.forEach((product) => {
      if (product.ProductType === 1) {
        this.foundMembership = true;
        return;
      }
    });
  }
  // check to see if there is a product type 3 in the purchased products list
  findExistingSubs() {
    this.purchasedProducts.forEach((product) => {
      if (product.ProductType === 3) {
        this.foundSubscription = true;
        return;
      }
    });
  }

  // get portal products of type 3 which are active and not purchased
  getActiveNonPurchasedSubs() {
    let portalProductIds: number[] = [];
    let purchasedProductIds: number[] = [];
    this.subscriptionPortalProducts.forEach((products) => {
      portalProductIds.push(products.ProductId);
    });
    this.purchasedProducts.forEach((purchasedProduct) => {
      purchasedProductIds.push(purchasedProduct.ProductId);
    });

    let nonPurchasedIds = portalProductIds.filter((x) => !purchasedProductIds.includes(x));

    nonPurchasedIds.forEach((ids) => {
      let item: PortalProduct = this.getProductsById(ids);
      this.notPurchasedActiveSubs.push(item);
    });
  }

  // get a portal product by product ID
  getProductsById(id: number): PortalProduct {
    this.subscriptionPortalProducts.forEach((prod) => {
      if (id === prod.ProductId) {
        this.individualProduct = prod;
      }
    });
    return this.individualProduct;
  }

  // remove item from basket
  remove(cartItem: Cart) {
    this.cartService.remove(cartItem.id);
  }

  // clear and cancel items in subs basket
  clearSubsBasket() {
    this.cartQuery.removeItems(this.cartQuery.getMemberPortalProductCart());
  }
  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
