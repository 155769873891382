import { Component, NgZone, OnInit } from '@angular/core';
import {
  Address,
  MemberProduct,
  PortalProductsService,
  ChildQuery,
  PaymentService,
  AddressType,
  AddressQuery,
  MemberQuery,
  MemberDetails,
  LoggingService,
  PortalProduct,
  PortalProductsQuery,
  ErrorStateService,
  GiftCodeModel,
  GiftCodeType,
  GiftCodeEntitlementValueType,
  GiftCodeService,
  ProviderActionKind,
  ChildService,
} from '@fgb/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { tap, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

interface ChildUpgrade {
  ID: number;
  MemberId: string;
  FirstName: string;
  LastName: string;
  DateOfBirth: Date | string;
  Product: PortalProduct;
}

class PortalProductWithDescription {
  PortalProduct: PortalProduct;
  ShowDescription: boolean;
  constructor(product: PortalProduct, show: boolean) {
    this.PortalProduct = product;
    this.ShowDescription = show;
  }
}

@Component({
  selector: 'fgb-upgrade-children',
  templateUrl: './upgrade-children.component.html',
  styleUrls: ['./upgrade-children.component.scss'],
})
export class UpgradeChildrenComponent implements OnInit {
  editingBillingAddress: boolean = false;
  editingShippingAddress: boolean = false;
  portalProducts: PortalProductWithDescription[] = [];
  childUpgrades: ChildUpgrade[] = [];
  appliedGiftCodes: GiftCodeModel[] = [];
  giftCodeInput: string;
  submitted: boolean;
  orderCompleted: boolean = false;
  loadingPaymentForm: boolean;
  addresses: Address[] = [];
  currentUser: MemberDetails | undefined;
  billingAddress: Address;
  billingAddressForm: UntypedFormGroup;
  currentStep: number = 0;
  hasSubmittedBillingAddress: boolean = false;
  hasSubmittedShippingAddress: boolean = false;
  shippingAddress: Address;
  shippingAddressForm: UntypedFormGroup;
  totalAmount: number = 0;
  totalShipping: number = 0;
  totalTax: number = 0;
  totalBasketAmount: number = 0;
  totalNumberOfChildren: number;
  disableApplyGiftCodesButton: boolean = false;
  noMembershipsSelected: boolean = false;
  paymentModal: any;

  constructor(
    private portalProductService: PortalProductsService,
    private childQuery: ChildQuery,
    private giftCodeService: GiftCodeService,
    private paymentService: PaymentService,
    private memberQuery: MemberQuery,
    private childService: ChildService,
    private addressQuery: AddressQuery,
    private logService: LoggingService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private portalProductQuery: PortalProductsQuery,
    private errorService: ErrorStateService,
    private _zone: NgZone
  ) {}

  ngOnInit() {
    this.childService.fetchChildren().toPromise();

    this.addressQuery
      .selectAddresses([AddressType.DefaultAddress])
      .subscribe((addresses) => {
        this.addresses = addresses;
        this.memberQuery
          .selectMemberDetails()
          .subscribe((md) => {
            this.currentUser = md;

            this.billingAddress = this._getDefaultBillingAddress();
            this.shippingAddress = this._getDefaultShippingAddress();
          })
          .unsubscribe();
      })
      .unsubscribe();

    this.shippingAddressForm = this.formBuilder.group({
      FirstName: [this.shippingAddress.FirstName, Validators.required],
      Surname: [this.shippingAddress.Surname, Validators.required],
      Street: [this.shippingAddress.Street, Validators.required],
      Town: [this.shippingAddress.Town, Validators.required],
      County: [this.shippingAddress.County, Validators.required],
      Postcode: [this.shippingAddress.Postcode, [Validators.required]],
      Country: [this.shippingAddress.Country, Validators.required],
      PortalId: [this.currentUser?.PortalId],
    });

    this.billingAddressForm = this.formBuilder.group({
      FirstName: [this.billingAddress.FirstName, Validators.required],
      Surname: [this.billingAddress.Surname, Validators.required],
      Street: [this.billingAddress.Street, Validators.required],
      Town: [this.billingAddress.Town, Validators.required],
      County: [this.billingAddress.County, Validators.required],
      Postcode: [this.billingAddress.Postcode, [Validators.required]],
      Country: [this.billingAddress.Country, Validators.required],
      PortalId: [this.currentUser?.PortalId],
    });

    this.portalProductService
      .fetchPortalProducts()
      .pipe(take(1))
      .subscribe(() => {
        let portalProducts = this.portalProductQuery.getAll() as PortalProduct[];

        // get only portalProducts that are paid for
        portalProducts = portalProducts.filter(
          (pp) => pp.MerchantPrice > 0 && !pp.DisplayName.includes('Rookie') && pp.ProductType === 4
        );
        portalProducts.forEach((pp) => {
          this.portalProducts.push(new PortalProductWithDescription(pp, false));
        });

        if (!this.portalProducts || this.portalProducts.length === 0) {
          this.errorService.addError('No portal products');
          return;
        }

        let defaultProduct = { PortalProduct: { ProductId: 0 } } as PortalProductWithDescription;

        this.childQuery
          .selectChildren()
          .pipe(
            tap((children) => {
              children.forEach((c) => {
                if (c.AccountPaymentState === 0) {
                  let childUpgrade: ChildUpgrade = {
                    ID: c.Id,
                    MemberId: c.MemberId,
                    FirstName: c.Firstname,
                    LastName: c.Lastname,
                    DateOfBirth: c.DateOfBirth,
                    Product: defaultProduct.PortalProduct,
                  };
                  this.childUpgrades.push(childUpgrade);
                }
              });
            })
          )
          .toPromise();

        this.calculateTotalAmount();
      });

    this.addressQuery
      .selectAddresses([AddressType.DefaultAddress])
      .subscribe((addresses) => {
        this.addresses = addresses;
        this.memberQuery
          .selectMemberDetails()
          .subscribe((md) => {
            this.currentUser = md;
            this.billingAddress = this._getDefaultBillingAddress();
            this.shippingAddress = this._getDefaultShippingAddress();
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  showNext(step: number) {
    this.currentStep = step;
    this.errorService.clearErrors();

    if (this.currentStep === 1) {
      let paidUpgrades = this.childUpgrades.filter((x) => x.Product.ProductId.toString() !== '0');
      if (!paidUpgrades || paidUpgrades.length === 0) {
        this.submitted = true;
      }
    } else if (this.currentStep === 2) {
      let messageToAppear = this.checkErrorMessages();

      if (!!messageToAppear) {
        this.errorService.addError(messageToAppear);
        this.currentStep = 1;
        return;
      }

      this.processPayment();
    }
  }

  checkErrorMessages(): string {
    let messageToAppear = '';

    if (this.billingAddress.Street === '') {
      messageToAppear += 'Billing Address - Please enter valid Street information \r\n';
    }
    if (this.billingAddress.Town === '') {
      messageToAppear += 'Billing Address - Please enter valid City information \r\n';
    }
    if (this.billingAddress.County === '') {
      messageToAppear += 'Billing Address - Please enter valid State information \r\n';
    }
    if (this.billingAddress.Postcode === '') {
      messageToAppear += 'Billing Address - Please enter valid Zip Code information \r\n';
    }
    if (this.billingAddress.Country === '') {
      messageToAppear += 'Billing Address - Please enter valid Country information \r\n';
    }
    if (this.shippingAddress.Street === '') {
      messageToAppear += 'Shipping Address - Please enter valid Street information \r\n';
    }
    if (this.shippingAddress.Town === '') {
      messageToAppear += 'Shipping Address - Please enter valid City information \r\n';
    }
    if (this.shippingAddress.County === '') {
      messageToAppear += 'Shipping Address - Please enter valid State information \r\n';
    }
    if (this.shippingAddress.Postcode === '') {
      messageToAppear += 'Shipping Address - Please enter valid Zip Code information \r\n';
    }
    if (this.shippingAddress.Country === '') {
      messageToAppear += 'Shipping Address - Please enter valid Country information \r\n';
    }

    return messageToAppear;
  }

  showModalPayment(template: any) {
    this.errorService.clearErrors();
    let messageToAppear = this.checkErrorMessages();

    if (!!messageToAppear) {
      this.errorService.addError(messageToAppear);
      this.currentStep = 1;
      return;
    }

    this.processPayment(template);
  }

  closePaymentModal() {
    this.paymentModal.close();
  }

  CloseModal() {
    this.modalService.dismissAll();
  }

  showPrevious(step: number) {
    if (step == 1) {
      if (this.paymentModal) this.closePaymentModal();
      this.calculateTotalAmount();
      this.loadingPaymentForm = false;
    }
    this.currentStep = step;
  }

  setAddress(address: Address) {
    this.loadingPaymentForm = true;
  }

  processPayment(template?: any) {
    this.loadingPaymentForm = true;
    let memberProducts: MemberProduct[] = this.childUpgrades
      .filter((item) => item.Product.ProductId > 0)
      .map((item) => {
        return { MemberId: item.MemberId, ProductId: item.Product.ProductId, MerchantPrice: item.Product.MerchantPrice };
      });
    firstValueFrom(this.portalProductService.purchase(memberProducts, this.billingAddress, this.appliedGiftCodes))
      .then((response) => {
        this.loadingPaymentForm = false;
        this.totalBasketAmount = response.Amount;
        if (response.ProviderActionInformation.ActionKind == ProviderActionKind.None) {
          this.orderCompleted = true;
          this.currentStep++;
          this.childService.disableChildStoreCache();
          this.childService.fetchChildren().toPromise();
        } else {
          if (template) {
            this.showNextModal(template, response);
          } else {
            this.paymentService.process(response, 'paymentForm', 'portalproducts/failure');
          }
        }
      })
      .catch((error) => {
        this.logService.error(error);
        this.loadingPaymentForm = false;
      });
  }

  showNextModal(template: any, response: any) {
    this.paymentModal = this.modalService.open(template, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'add-payment-details-modal',
    });
    this._zone.onStable.pipe(take(1)).subscribe(() => {
      this.paymentService.process(response, 'paymentForm', 'portalproducts/failure');
    });
  }

  async updateBillingAddress() {
    this.hasSubmittedBillingAddress = true;

    if (this.billingAddressForm.valid) {
      this.billingAddress = { ...this.billingAddress, ...this.billingAddressForm.value };

      this.editingBillingAddress = false;
    }
  }

  async updateShippingAddress() {
    this.hasSubmittedShippingAddress = true;

    if (this.shippingAddressForm.valid) {
      this.shippingAddress = { ...this.shippingAddress, ...this.shippingAddressForm.value };
      this.editingShippingAddress = false;
    }
  }

  /** Clear the billing address form fields. */
  clearAddressForm(addressForm: UntypedFormGroup) {
    addressForm.patchValue({
      Street: '',
      Town: '',
      County: '',
      Postcode: '',
      Country: '',
    });
  }

  /** Start editing the user's billing address. */
  editBillingAddress() {
    this.editingBillingAddress = true;
    this.logService.debug('Opening billing address form with initial value', this.billingAddress);
  }

  /** Start editing the user's shipping address. */
  editShippingAddress() {
    this.editingShippingAddress = true;
    this.logService.debug('Opening shipping address form with initial value', this.editingShippingAddress);
  }

  childProductChange(child: ChildUpgrade, productId: number) {
    let selectedProduct = this.portalProducts.find((pp) => pp.PortalProduct.ProductId === +productId);
    if (child && selectedProduct) {
      child.Product = selectedProduct.PortalProduct;
    } else if (child && !selectedProduct) {
      child.Product = { ProductId: 0 } as PortalProduct;
    }

    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    this.totalAmount = this.childUpgrades.reduce((tot, cu) => {
      if (cu.Product.ProductId > 0) {
        return tot + cu.Product.MerchantPrice;
      }
      return tot;
    }, 0);

    let memberProducts: MemberProduct[] = this.childUpgrades
      .filter((item) => item.Product.ProductId > 0)
      .map((item) => {
        return { MemberId: item.MemberId, ProductId: item.Product.ProductId, MerchantPrice: item.Product.MerchantPrice };
      });

    this.noMembershipsSelected = !memberProducts.length;

    if (memberProducts.length) {
      this.portalProductService
        .calculateAdditionalCharges(memberProducts, false, this.appliedGiftCodes)
        .pipe(
          tap((charges) => {
            this.totalTax = charges.TotalTax;
            this.totalShipping = charges.TotalShipping;
            this.totalBasketAmount = this.totalAmount + this.totalShipping + this.totalTax;
          })
        )
        .toPromise()
        .then(() => {
          if (this.appliedGiftCodes.length > 0) {
            this.appliedGiftCodes.forEach((x) => {
              this._calculateGiftCodeDeduction(x);
            });
          }
        });
    } else {
      this.totalTax = 0;
      this.totalShipping = 0;
      this.totalBasketAmount = 0;
    }
  }

  /** Gets the user's address from the personal details step data. */
  private _getDefaultBillingAddress(): Address {
    if (this.addresses) {
      let billingAddress1 = this.addresses.filter((a) => a.AddressType === AddressType.BillingAddress1)[0] as Address;
      if (billingAddress1) {
        return billingAddress1;
      }

      let billingAddress2 = this.addresses.filter((a) => a.AddressType === AddressType.BillingAddress2)[0] as Address;
      if (billingAddress2) {
        return billingAddress2;
      }
    }
    return {
      AddressName: 'Billing Address',
      FirstName: this.currentUser?.FirstName,
      Surname: this.currentUser?.Surname,
      Street: this.currentUser?.Street,
      Town: this.currentUser?.Town,
      County: this.currentUser?.County,
      Postcode: this.currentUser?.PostCode,
      Country: this.currentUser?.Country,
      EmailAddress: this.currentUser?.EmailAddress1,
      PortalId: this.currentUser?.PortalId,
    } as Address;
  }

  private _getDefaultShippingAddress(): Address {
    if (this.addresses) {
      let shippingAddress1 = this.addresses.filter((a) => a.AddressType === AddressType.ShippingAddress1)[0] as Address;
      if (shippingAddress1) {
        return shippingAddress1;
      }

      let shippingAddress2 = this.addresses.filter((a) => a.AddressType === AddressType.ShippingAddress2)[0] as Address;
      if (shippingAddress2) {
        return shippingAddress2;
      }
    }

    if (this.currentUser) {
      return {
        AddressName: 'Shipping Address',
        FirstName: this.currentUser.FirstName,
        Surname: this.currentUser.Surname,
        Street: this.currentUser.Street,
        Town: this.currentUser.Town,
        County: this.currentUser.County,
        Postcode: this.currentUser.PostCode,
        Country: this.currentUser.Country,
        EmailAddress: this.currentUser.EmailAddress1,
        PortalId: this.currentUser.PortalId,
      } as Address;
    } else {
      let shippingAddressCopy = Object.assign({}, this.billingAddress);
      shippingAddressCopy.AddressName = 'Shipping Address';
      return shippingAddressCopy;
    }
  }

  applyGiftCode() {
    this.errorService.clearErrors();

    if (this.disableApplyGiftCodesButton) {
      return;
    }

    if (!this.giftCodeInput) {
      this.errorService.addError('Gift Code cannot be empty');
      return;
    }

    if (!!this.appliedGiftCodes.filter((code) => code.Code === this.giftCodeInput).length) {
      this.errorService.addError('This gift code has already been applied');
      return;
    }

    if (this.totalAmount === 0) {
      this.errorService.addError('Gift code cannot be applied if the basket value is 0');
      return;
    }

    if (this.appliedGiftCodes.length > 0) {
      this.errorService.addError('Only one gift code can be applied.');
      return;
    }

    this.disableApplyGiftCodesButton = true;

    firstValueFrom(this.giftCodeService.validateGiftCode(this.giftCodeInput))
      .then((giftCode) => {
        if (
          giftCode.GiftCodeAssignType == GiftCodeType.Product &&
          !this.childUpgrades.filter(
            (item) =>
              item.Product.ProductId == giftCode.EntitlementItemId || item.Product.ProductId == giftCode.EntitlementItemId2
          ).length
        ) {
          this.errorService.addError('Gift code does not apply to any of the basket items.');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (
          giftCode.EntitlementValueType == GiftCodeEntitlementValueType.Percentage &&
          !!this.appliedGiftCodes.filter((gc) => gc.EntitlementValueType == GiftCodeEntitlementValueType.Percentage).length
        ) {
          this.errorService.addError('Percentage discount can only be applied once to an order.');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (giftCode.GiftCodeStatus != 0) {
          this.errorService.addError('Gift code has been used');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (!giftCode.Active) {
          this.errorService.addError('Gift code not available.');
          this.disableApplyGiftCodesButton = false;
          return;
        }

        if (giftCode.EntitlementValueType === GiftCodeEntitlementValueType.Value) {
          this.appliedGiftCodes.unshift(giftCode);
        } else {
          this.appliedGiftCodes.push(giftCode);
        }

        this.disableApplyGiftCodesButton = false;

        this.appliedGiftCodes.forEach((x) => {
          this._calculateGiftCodeDeduction(x);
        });

        this.calculateTotalAmount();
      })
      .catch((err) => {
        this.errorService.addError(err);
        this.disableApplyGiftCodesButton = false;
      });
  }

  /** Calculate the new total basket price after Gift Code has been applied */
  private _calculateGiftCodeDeduction(giftCode: GiftCodeModel) {
    this.errorService.clearErrors();
    let discount = 0;

    if (giftCode.GiftCodeAssignType === GiftCodeType.Order) {
      switch (giftCode.EntitlementValueType) {
        // Value
        case GiftCodeEntitlementValueType.Value: {
          discount = giftCode.EntitlementValue;
          break;
        }
        case GiftCodeEntitlementValueType.Percentage: {
          // Percentage
          discount = (giftCode.EntitlementValue / 100) * this.totalBasketAmount;
          break;
        }
        case GiftCodeEntitlementValueType.NumberOfEntitlements: {
          // Number of Items - Only used for Free
          this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x.Code === giftCode.Code);
          discount = this.totalBasketAmount;
          break;
        }
        default: {
          this.errorService.addError('Invalid Gift Code');
          break;
        }
      }
    } else if (giftCode.GiftCodeAssignType === GiftCodeType.Shipping) {
      if (this.totalShipping === 0) {
        this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x.Code !== giftCode.Code);
        this.errorService.addError('Gift Code cannot be applied if the shipping is already 0');
        return;
      }

      switch (giftCode.EntitlementValueType) {
        case GiftCodeEntitlementValueType.Value: {
          // Value
          discount = giftCode.EntitlementValue;
          break;
        }
        case GiftCodeEntitlementValueType.Percentage: {
          // Percentage
          discount = (giftCode.EntitlementValue / 100) * this.totalShipping;
          break;
        }
        case GiftCodeEntitlementValueType.NumberOfEntitlements: {
          // Number of Items - Only used for Free
          discount = this.totalShipping;
          break;
        }
        default: {
          this.errorService.addError('Invalid Gift Code');
          break;
        }
      }
    } else if (giftCode.GiftCodeAssignType == GiftCodeType.Product) {
      var numberOfTimesApplied = 0;
      this.childUpgrades
        .filter(
          (item) => item.Product.ProductId == giftCode.EntitlementItemId || item.Product.ProductId == giftCode.EntitlementItemId2
        )
        .forEach((item) => {
          if (numberOfTimesApplied < giftCode.EntitlementValue) {
            numberOfTimesApplied++;
            discount += item.Product.MerchantPrice;
          }
        });
    }
    this.totalBasketAmount = Math.max(0, this.totalBasketAmount - discount);
  }

  /** Works out the total basket price after removal of gift code */
  removeGiftCode(code: string) {
    this.errorService.clearErrors();
    let giftCode = this.appliedGiftCodes.find((x) => x.Code === code);

    if (giftCode) {
      this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x !== giftCode);

      /** reset basket values and recalculate with remaining giftcodes */
      this.calculateTotalAmount();

      if (this.appliedGiftCodes.length > 0) {
        this.appliedGiftCodes.forEach((x) => {
          this._calculateGiftCodeDeduction(x);
        });
      }
    }
  }

  /** Cancel edit address */
  cancelAndRebuildForm(addressType: String) {
    if (addressType == 'billing') {
      this.editingBillingAddress = false;

      this.billingAddressForm = this.formBuilder.group({
        FirstName: [this.billingAddress.FirstName, Validators.required],
        Surname: [this.billingAddress.Surname, Validators.required],
        Street: [this.billingAddress.Street, Validators.required],
        Town: [this.billingAddress.Town, Validators.required],
        County: [this.billingAddress.County, Validators.required],
        Postcode: [this.billingAddress.Postcode, Validators.required],
        Country: [this.billingAddress.Country, Validators.required],
      });
    } else if (addressType == 'shipping') {
      this.editingShippingAddress = false;

      this.shippingAddressForm = this.formBuilder.group({
        FirstName: [this.shippingAddress.FirstName, Validators.required],
        Surname: [this.shippingAddress.Surname, Validators.required],
        Street: [this.shippingAddress.Street, Validators.required],
        Town: [this.shippingAddress.Town, Validators.required],
        County: [this.shippingAddress.County, Validators.required],
        Postcode: [this.shippingAddress.Postcode, [Validators.required]],
        Country: [this.shippingAddress.Country, Validators.required],
      });
    }
  }
}
