import { Component, OnInit } from '@angular/core';
import {
  Barcode,
  BarcodeQuery,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  SeatingDetailQuery,
  SeatingDetail,
  ProductCodeQuery,
} from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { Observable } from 'rxjs';
import { MemberCard } from '@fgb/core';
import { config } from '@fgb/core';
import { BalanceQuery } from '@fgb/core';

@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  barcode$: Observable<Barcode>;
  card$: Observable<MemberCard | undefined>;
  member$: Observable<MemberDetails | undefined>;
  seatingDetail$: Observable<SeatingDetail | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  ecash$: Observable<number | undefined>;
  pointsBalance$: Observable<number | undefined>;
  bgtheme: string;
  texttheme: string;
  lowerTier: boolean;
  upperTier: boolean;
  maxTier: boolean;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private memberDetailsQuery: MemberQuery,
    private seatingDetailQuery: SeatingDetailQuery,
    private memberQuery: MemberQuery,
    private productCodeQuery: ProductCodeQuery
  ) {}

  ngOnInit() {
    this.seatingDetail$ = this.seatingDetailQuery.selectCurrentSeatingDetails();
    this.barcodeService.fetchBarcode().toPromise();
    this.barcode$ = this.barcodeQuery.select();
    this.member$ = this.memberDetailsQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.lowerTier = this.productCodeQuery.currentUserIsProductCode(['FREEMEMBER', 'Tier1']);
    this.upperTier = this.productCodeQuery.currentUserIsProductCode(['Tier2', 'Tier3']);
    this.maxTier = this.productCodeQuery.currentUserIsProductCode(['Tier4']);
    this.updateBackgroundColour();
  }

  updateBackgroundColour() {
    if (this.lowerTier) {
      this.bgtheme = 'bg-primary';
    } else if (this.upperTier) {
      this.bgtheme = 'bg-quaternary';
    } else if (this.maxTier) {
      this.bgtheme = 'bg-gold';
      this.texttheme = 'text-secondary';
    } else {
      this.bgtheme = 'bg-primary';
    }
  }
}
