<div class="banner">
  <h1>{{ 'featured.rewards.page.title' | translate }}</h1>
</div>

<ng-container *ifScreenSize="screenSize.Desktop">
  <ng-container *notInProductCode="[productCode.Free]">
    <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
  </ng-container>
</ng-container>

<!-- Filter options-->
<fgbcl-filter-modal
  [filterTypesList]="filterTypesList"
  (filterTypesListEmitter)="handleFilterTypesListEmitter($event)"
  [selectedValues]="selectedValues"
  (selectedValuesEmitter)="handleSelectedValuesEmitter($event)"
  [showOffCanvas]="showOffCanvas"
  (showOffCanvasEmitter)="handleShowOffCanvasEmitter($event)"
>
</fgbcl-filter-modal>

<div class="container mt-3">
  <fgbcl-mixed-loyalty-list [onlyFavourites]="true" [filterOptionsList]="selectedValues"></fgbcl-mixed-loyalty-list>
</div>
