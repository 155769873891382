import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, Cart, CartQuery } from '@fgb/core';

@Component({
  selector: 'fgb-portal-product-success-page',
  templateUrl: './portal-product-success-page.component.html',
  styleUrls: ['./portal-product-success-page.component.scss'],
})
export class PortalProductSuccessPageComponent implements OnInit, OnDestroy {
  cartItem: Cart;

  constructor(private cart: CartQuery, private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.cartItem = this.cart.getMemberPortalProductCart()[0];
  }

  ngOnDestroy(): void {
    this.cart.removeItems([this.cartItem]);
  }

  updateAccount() {
    this.authService
      .refreshAccessToken()
      .toPromise()
      .then(() => {
        this.router.navigate(['/account']).then(() => {
          location.reload();
        });
      });
  }
}
