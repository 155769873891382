<div class="text-black" *ngIf="barcode$ | async as barcode">
  <div class="">
    <div class="p-0 d-flex flex-wrap flex-column justify-content-around align-items-center rounded border w-100">
      <ng-container *ngIf="card$ | async as card">
        <img alt="" class="logo" src="/assets/images/memberships/barcode-{{ card.ProductCode }}.svg" draggable="false" />

        <!--Name and ID-->
        <div class="card-id text-center pt-3">
          <h5 class="mb-1 card-name text-secondary" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </h5>
          <div class="d-flex justify-content-center font-size-10">
            <div>{{ card.ProductCode }}</div>
            <div class="px-1">-</div>
            <div>{{ card.MemberId }}</div>
          </div>
        </div>
      </ng-container>

      <div class="qr">
        <qrcode [qrdata]="barcode.Barcode" [width]="125" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
      </div>
    </div>

    <!-- Barcode Body -->
    <div class="w-100 barcode-content-container d-flex justify-content-center align-items-center {{ bgtheme }} ">
      <div class="barcode-content text-white">
        <div class="d-flex align-items-end">
          <div class="card-account-no text-center {{ texttheme }}" *ngIf="pointsBalance$">
            <label class="font-size-14">{{ pointsBalance$ | async | FortressCurrencyPipe : '' : '' : '1.0-0' }}</label>
            <div class="">{{ 'member.card.points.balance' | translate }}</div>
          </div>
          <!-- <div class="card-id text-center ps-3 pe-3" *ngIf="ecash$">
            <label class="font-size-14">{{ ecash$ | async | FortressCurrencyPipe : 'GBP' }}</label>
            <div class="">{{ 'member.card.ecash.balance' | translate }}</div>
          </div>
          <ng-container *ngIf="card$ | async as card">
            <div class="card-id text-center ps-3 border-start-discount" *ngIf="card.DiscountLevel">
              <label class="font-size-14">{{ card.DiscountLevel }}%</label>
              <div class="">{{ 'member.card.discount' | translate }}</div>
            </div>
          </ng-container> -->
        </div>
      </div>
    </div>
  </div>
</div>
