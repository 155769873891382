<ng-container *ngIf="autoTopUpSubscription$ | async as autoTopUpSubscription">
  <div class="card">
    <div class="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom-0 pe-2">
      <div class="float-start text-primary ecash-load-heading fw-bold">AUTO RELOAD</div>
      <div
        *ngIf="
          autoTopUpSubscription.AutoTopUpItems &&
          autoTopUpSubscription.AutoTopUpItems.length > 0 &&
          autoTopUpSubscription.AutoTopUpItems[0].HasSubscription
        "
        class="float-end row no-gutters align-items-center m-1"
      >
        <div
          [ngClass]="{ 'text-muted': !autoTopUpSubscription.AutoTopUpItems[0].Active }"
          class="status-text fw-bold text-success"
        >
          {{ autoTopUpSubscription.AutoTopUpItems[0].Active ? 'Active' : 'InActive' }}
        </div>
        <label class="checkbox-switch mx-2 d-block position-relative">
          <input
            type="checkbox"
            [checked]="autoTopUpSubscription.AutoTopUpItems[0].Active"
            (change)="
              toggleSubscription(
                autoTopUpSubscription.AutoTopUpItems[0].Active,
                autoTopUpSubscription.AutoTopUpItems[0].Threshold,
                autoTopUpSubscription.AutoTopUpItems[0].Amount
              )
            "
          />
          <div class="slider round"></div>
        </label>
        <button
          attr.aria-label="{{ 'ecash.open.topup.modal.screenreader' | translate }}"
          class="float-end btn p-0 text-primary fw-bold"
          (click)="open(manualtopUpModal)"
        >
          <span class="material-icons align-middle">info</span>
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <div *ngIf="autoTopUpSubscription.AutoTopUpTresholds && autoTopUpSubscription.AutoTopUpTresholds.length > 1" class="row">
        <div class="col-6">
          <div class="mt-2 mb-1 label">Below Amount</div>
          <select
            [disabled]="
              autoTopUpSubscription.AutoTopUpItems &&
              autoTopUpSubscription.AutoTopUpItems.length > 0 &&
              autoTopUpSubscription.AutoTopUpItems[0].HasSubscription &&
              !autoTopUpSubscription.AutoTopUpItems[0].Active
            "
            (change)="onBelowChange($event.target.value)"
            class="form-control mb-2"
            [value]="
              autoTopUpSubscription.AutoTopUpItems && autoTopUpSubscription.AutoTopUpItems.length > 0
                ? autoTopUpSubscription.AutoTopUpItems[0].Threshold
                : autoTopUpSubscription.AutoTopUpTresholds[0]
            "
          >
            <option *ngFor="let amount of autoTopUpSubscription.AutoTopUpTresholds" [value]="amount">
              {{ amount / 100 | FortressCurrencyPipe : 'USD' }}
            </option>
          </select>
        </div>
        <div class="col-6">
          <div class="mt-2 mb-1 label">Load Amount</div>
          <select
            [disabled]="
              autoTopUpSubscription.AutoTopUpItems &&
              autoTopUpSubscription.AutoTopUpItems.length > 0 &&
              autoTopUpSubscription.AutoTopUpItems[0].HasSubscription &&
              !autoTopUpSubscription.AutoTopUpItems[0].Active
            "
            (change)="onAmountChange($event.target.value)"
            class="form-control mb-2"
            [value]="
              autoTopUpSubscription.AutoTopUpItems && autoTopUpSubscription.AutoTopUpItems.length > 0
                ? autoTopUpSubscription.AutoTopUpItems[0].Amount
                : autoTopUpSubscription.AutoTopUpAmounts[0]
            "
          >
            <option *ngFor="let amount of autoTopUpSubscription.AutoTopUpAmounts" [value]="amount">
              {{ amount / 100 | FortressCurrencyPipe : 'USD' }}
            </option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="hasSubscription$ | async; else noSubscription">
        <button
          [disabled]="!autoTopUpSubscription.AutoTopUpItems[0].Active"
          class="btn btn-block btn-success sub-heading-modal fw-bold mt-3"
          (click)="open(confirmUpdate)"
        >
          Update
        </button>
      </ng-container>

      <ng-template #noSubscription>
        <button
          class="col-12 btn btn-success btn-setup fw-bold"
          (click)="open(confirm)"
          [disabled]="!belowAmount || !topUpAmount"
        >
          Activate eCash Auto Reload
        </button>
      </ng-template>
    </div>
  </div>
  <button
    class="btn btn-link btn-sm sub-heading-modal fw-bold btn-block text-primary p-0 mt-2"
    (click)="open(confirmDelete)"
    *ngIf="hasSubscription$ | async"
  >
    Delete Payment Card
  </button>

  <!-- Modals -->

  <ng-template #confirm let-modal>
    <div class="modal-content">
      <div class="modal-body text-center p-2">
        <div class="modal-header d-block text-center border-bottom-0">
          <div class="drinks-icon icon svg svg-primary mb-2 mx-auto" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
          <h3 class="modal-heading text-primary">Auto Load ecash</h3>
          <div class="my-4 sub-heading-modal fw-bold">
            Are you sure you want to top up
            <span class="text-success fw-bolder"> {{ topUpAmount / 100 | FortressCurrencyPipe : 'USD' }}</span> every time your
            balance is below the amount of
            <span class="text-success fw-bolder">{{ belowAmount / 100 | FortressCurrencyPipe : 'USD' }}</span>
            ?
          </div>
        </div>
        <div id="paymentDiv" class="payment-modal mt-4 mx-auto"></div>
        <div class="btn-container mt-5 d-block">
          <button type="button" class="btn btn-danger m-2 btn-text fw-bold" (click)="modal.dismiss()">Cancel</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmUpdate let-modal>
    <div *ngIf="autoTopUpSubscription.AutoTopUpItems && autoTopUpSubscription.AutoTopUpItems.length > 0" class="modal-content">
      <div class="modal-body text-center p-2">
        <div class="modal-header d-block text-center border-bottom-0">
          <div class="drinks-icon icon svg svg-primary mb-2 mx-auto" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
          <h3 class="modal-heading text-primary">Auto Load ecash</h3>
          <div class="my-4 sub-heading-modal fw-bold">
            Are you sure you want to top up
            <span class="text-success fw-bolder"> {{ topUpAmount / 100 | FortressCurrencyPipe : 'USD' }}</span> every time your
            balance is below the amount of
            <span class="text-success fw-bolder">{{ belowAmount / 100 | FortressCurrencyPipe : 'USD' }}</span>
            ?
          </div>
        </div>
        <div class="modal-body text-center">
          <div class="btn-container">
            <button type="button" class="btn btn-danger m-2 fw-bold" (click)="modal.dismiss()">Cancel</button>
            <button
              type="button"
              class="btn btn-success m-2 fw-bold"
              (click)="
                updateSubscription(
                  autoTopUpSubscription.AutoTopUpItems[0].Amount,
                  autoTopUpSubscription.AutoTopUpItems[0].Threshold
                )
              "
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #autoTopUpModal let-modal>
    <div class="modal-header">
      <div class="modal-title">
        <span class="material-icons align-middle">payments</span>
        <div>How to set up Auto Top-Up</div>
      </div>
      <button type="button" class="close" attr.aria-label="{{ 'modal.close' | translate }}" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <p>To initially set up auto top-up set your below and top-up amount.</p>
        <p>If your account falls below the below amount your top-up amount will automatically be added.</p>
        <p>Once set up you can inactivate your auto top-up without removing your card details, or update your amounts.</p>
        <p>Clicking delete will cancel your auto top-up and remove your card details from the system.</p>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmDelete let-modal>
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="sub-heading-modal fw-bold my-5">Are you sure you want to delete your payment card?</div>
        <div class="btn-container btn-text fw-bold">
          <button type="button" class="btn btn-danger m-2" (click)="modal.dismiss()">Cancel</button>
          <button type="button" class="btn btn-success m-2" (click)="deleteSubscription()">Confirm</button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #manualtopUpModal let-modal>
  <div class="info-modal">
    <div class="bg-primary text-white">
      <div class="modal-header border-bottom-0 d-block text-center pe-2 mb-3">
        <div class="drinks-icon icon svg svg-white mb-3 mt-2 mx-auto" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
        <h3 class="modal-heading">How to auto reload</h3>
        <div class="close-container position-absolute me-3 ms-auto">
          <div class="icon material-icons align-middle" (click)="modal.dismiss('close')"></div>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <p>
            Auto reload will continually reload your membership card with eCash, every time your balance falls below the
            designated amount.
          </p>
          <p>
            <span class="fw-bold">Step 1.</span> In “Below Amount” enter the price point that you want your account to be
            automatically reloaded.
          </p>
          <p>
            <span class="fw-bold">Step 2.</span> In “Load Amount” enter the amount that will be added to your membership card once
            your balance goes below the point outlined in step 1.
          </p>
          <p><span class="fw-bold">Step 3.</span> Click the green Activate eCash Auto Reload button.</p>
          <p><span class="fw-bold">Step 4.</span> Enter your credit card or debit card details in the fields required.</p>
          <p><span class="fw-bold">Step 5.</span> Click Submit.</p>
          <p>Once set up you can disable your auto reload without removing your card details, or update your amounts.</p>
          <p>Clicking delete payment card will cancel your auto reload and remove your card details from the system.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
